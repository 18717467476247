.success-box, .error-box {
  margin: 0 auto;

  width: 75%;
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    width: 90%;
  }
  @media (max-width: @screen-xs-max) {
    width: 100%;
  }

  .panel-body {
    padding: 3rem;
  }

  &__icon {
    font-size: 600%;
    margin-bottom: 1rem;
  }

  &__info {
    margin-top: 2rem;
  }

  &__block {
    margin: 2rem 3em;
    .text-left;

    @media (max-width: @screen-xs-max) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__top-space {
    margin-top: 2rem;
  }
  &__bottom-space {
    margin-bottom: 2rem;
  }
  &__left-space {
    margin-left: 2rem;
  }
}
