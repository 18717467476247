.results {
  &__button {
    margin: 0 1em 1em 0;
  }

  &__suggested-visualization {
    margin-top: 2rem;
    margin-bottom: -1rem;
    font-size: 1.1em;
    color: @gray;
  }
}

@donut-success: @brand-success;
@donut-warning: #F97651;
@donut-danger: @brand-danger;
@donut-default: #EBEBEB;
.results-donut {
  width: 100px;
  height: 100px;
  aspect-ratio: 1;
  border-radius: 100%;
  padding: 10px;

  &--success {
    background: conic-gradient(
      @donut-default 0deg var(--angle, 0deg),
      @donut-success var(--angle, 0deg) 360deg
    );
  }
  &--warning {
    background: conic-gradient(
      @donut-default 0deg var(--angle, 0deg),
      @donut-warning var(--angle, 0deg) 360deg
    );
  }
  &--danger {
    background: conic-gradient(
      @donut-default 0deg var(--angle, 0deg),
      @donut-danger var(--angle, 0deg) 360deg
    );
  }

  &__score {
    width: 100%;
    height: 100%;
    background: white;
    color: #363636;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-weight: 900;
  }
}

.results-stats {
  color: #363636;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &__title {
    font-size: 1.5em;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-weight: bold;
    text-align: center;
  }

  &__card {
    max-width: 280px;
    width: 100%;
    background: white;
    border-radius: 15px;
    box-shadow: 0px 1px 2px 1px #00000040;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 25px;
    margin: 1rem 0;
    @media (max-width: @screen-md-max) {
      margin: 1rem;
    }

    &__title {
      height: 3rem;
      font-weight: bold;
      font-size: 1.2em;
      text-align: center;
    }

    &__item {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .results-donut {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }

    a {
      cursor: pointer;
      display: inline-block;
      margin-top: 1rem;
      color: @brand-success;
      border: 1px solid @brand-success;
      padding: 5px 15px;
      border-radius: 6px;
      font-weight: bold;
      text-decoration: none !important;

      &:not(.disabled) {
        &:hover, &:active {
          background: @brand-success;
          color: white;
        }
      }

      &.disabled {
        cursor: not-allowed;
        border-color: @gray-light;
      }
    }
  }

  &__cards {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: @screen-sm-max) {
      flex-wrap: nowrap;
      flex-direction: column;
    }
  }

  &__selected-tag {
    color: black;
    position: relative;
    min-width: 100%;
    background: white;
    border-radius: 15px;
    box-shadow: 0px 1px 2px 0px #00000040;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding: 10px;

    &__close {
      position: absolute;
      right: 10px;
      top: 15px;
      cursor: pointer;
      font-size: 36px;
      color: #212528;
    }

    &__title {
      font-size: 1.5em;
      margin-top: 1rem;
      margin-bottom: 2rem;
      font-weight: bold;
      text-align: center;
    }

    &__periods {
      width: 100%;
      max-width: 350px;
      margin-bottom: 3rem;

      &__buttons {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 10px;
        margin: 1rem 0 1.5rem 0;

        .xbutton {
          text-align: center;
          box-shadow: 0px 1px 2px 0px #00000040;
          font-weight: bold;
          line-height: 1.5em;
        }
      }

      .results-stats__card {
        margin: 0;
        max-width: 100%;
      }
    }

    &__chapters {
      width: 100%;
      max-width: 700px;
      margin-top: -1rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: center;

      .results-stats__card {
        max-width: 170px;
        padding: 15px;
        margin: 1rem;
        &__title {
          height: 4rem;
          font-size: 1em;
          line-height: 1.4em;
        }

        &__item {
          font-size: 0.9em;
        }
      }
    }
  }
}
