.xcollapse {
  &__content.container{
    width: 100%;
    box-sizing: border-box;
  }
  &__title {
    .text-uppercase;

    margin: 1.5rem auto;
    color: @gray;
    opacity: 0.8;
    cursor: pointer;

    i.fa-caret-down, i.fa-caret-right {
      margin-left: 1rem;
    }

    &--open {
      opacity: 1;
    }
  }
}
