@green: @brand-success;

.disabled .lesson {
  &-individual, &-negotiation, &-group {
    &, &:hover {
      color: #bbb !important;
    }
  }

  &__assign {
    &__type {
      .tag {
        border-color: transparent;
        .btn {
          position: absolute;
          bottom: -15px;
          left: 0;
          width: 100%;
        }
      }
    }
  }
}

.lesson {
  &-individual {
    &, &:not(.disabled):hover, &:not(.disabled):focus {
      color: #8d3091 !important;
    }
  }
  &-negotiation {
    &, &:not(.disabled):hover, &:not(.disabled):focus {
      color: #ae6d46 !important;
    }
  }
  &-group {
    &, &:not(.disabled):hover, &:not(.disabled):focus {
      color: @blue-base !important;
    }
  }

  &__start {
    max-width: 65rem;
  }

  &__tags {
    font-size: 1.1rem;
    margin-bottom: 64px;

    .tag__header {
      margin-bottom: 6px;
    }
  }

  &__showresults {
    margin-bottom: 32px;
    i {
      margin-right: 8px;
    }
  }

  &__header {
    a:first-of-type {
      margin-left: 20px;
    }
  }

  &__header-inline {
    margin: 0;
    @media (min-width: @screen-md-min) {
      margin-right: 6rem;
    }
    display: inline-block;
  }
  margin: 0 auto 5rem auto;
  position: relative;
  min-height: 25rem;

  &--top-space {
    @media (max-width: @screen-xs-max) {
      padding-top: 3rem;
    }
  }

  &__tollbar {
    width: 100%;
  }

  &__start &__navigation {
    position: static !important;
  }

  &__fullscreen-icon {
    margin-right: 20px;
  }

  &__image-container {
    margin-top: 12px;
    text-align: center;
  }

  &__image {
    max-width: 100%;
  }

  &--centered {
    text-align: center;

    > div {
      @media (min-width: @screen-md) {
        margin-right: 6rem;
      }
    }
  }

  &__hint {
    margin: 0 5rem;
    &-continue {
      float: right;
    }
  }

  &__pages {
    position: absolute;
    right: 0;
    font-size: 110%;
    text-align: center;
    width: 6rem;
    white-space: nowrap;

    &__fixed {
      width: 6rem;
      position: fixed;
      bottom: 35px;
      @media (max-width: @screen-sm-max) {
        position: static;
      }
    }

    @media (max-width: @screen-sm-max) {
      bottom: -4.5rem;
    }

    .pull-right {
      margin-right: 10px;
    }
  }
  &__large-page {
    font-size: 170%;
  }

  h3 {
    @media (max-width: @screen-sm-max) {
      font-size: 1.6rem;
    }
  }

  &__navigation {

    > div {
      display: flex;
      margin-left: -25px;
      align-items: center;
      flex-wrap: wrap;
    }

    @media (min-width: @screen-md-min) {
      position: absolute;
      left: 100%;
      text-align: center;
      top: 0;
      margin-left: -4rem;

      > div {
        flex-direction: column;
      }

      .affix {
        top: 75px;
      }
      .affix-teacher {
        top: 75px + 65px;
      }
    }

    @media (max-width: @screen-sm-max) {
      .lesson {
        &__previous, &__next, &__creation, &__deletion, &__skip, &__opt {
          line-height: 1;
          i {
            font-size: 60%;
          }
          .fa-play {
            font-size: 45%!important;
          }
          @media (max-width: @screen-xs-max) {
            a {
              margin-left: 1rem;
              font-size: 84%;
            }
          }
        }
        &__creation {
          margin-left: 0!important;
        }
        &__previous {
          order: -1;
        }
        &__next{
          order: -2;
        }
      }
      > div {
        flex-direction: row;
        padding-bottom: 14px;
      }
      .affix, .affix-teacher {
        position: static !important;
      }
    }

    @media (max-width: @screen-xs-max) {
      margin-left: 1rem;
    }

    &--nowidth {
      width: auto;
    }
  }

  &__previous, &__next, &__creation, &__deletion, &__skip, &__opt {
    font-size: 450%;
    line-height: 1.2;
    @media (min-width: @screen-md-min) {
      float: none;
      width: 5rem;
    }

    .text-center;

    a {
      display: block;
      text-decoration: none;
      @media (min-width: @screen-md-min) {
        float: none;
        margin-top: -4px;
      }
      @media (max-width: @screen-md-min) {
        margin-left: 2rem;
      }
      i {
        font-size: 80%;
      }
    }

    .np-label {
      display: block;
      font-size: 20%;
    }
  }

  &__next {
    .next {
      &, &:focus, &:active {
        color: @blue-base;
      }

      &:hover {
        color: darken(@blue-base, 10%);
      }

      &.disabled {
        color: @gray-light;
      }
    }
    .cancel {
      color: @brand-danger;

      &:hover {
        color: darken(@brand-danger, 10%);
      }
    }
    .disabled {
      color: @gray-light;
      cursor: not-allowed;
    }
  }

  &__answer {
    color: @green;
    &:focus {
      color: @green;
    }

    &:hover {
      color: darken(@green, 10%);
    }
  }

  &__creation {
    font-size: 450%;

    @media (min-width: @screen-md-min){
      margin-bottom: 16px;
    }
    @media (max-width: @screen-md-min){
      margin-left: 16px;
    }
    a {
      color: @brand-success;

      .np-label {
        line-height: 1.2;
      }

      i {
        font-size: 100%;
      }

      &:hover {
        color: darken(@brand-success, 10%);
      }
    }
  }

  &__previous {
    a {
      color: lighten(@gray, 10%);

      &:hover {
        color: @gray;
      }
    }
  }

  &__skip {
    font-size: 550%;

    @media (min-width: @screen-md-min){
      margin-bottom: 22px;
    }
    @media (max-width: @screen-md-min){
      margin-left: 22px;
    }
    a {
      color: @brand-success;

      .np-label {
        line-height: 1.2;
      }

      i {
        font-size: 100%;
      }

      &:hover {
        color: darken(@brand-success, 10%);
      }
    }
  }

  &__previous {
    a {
      color: lighten(@gray, 10%);

      &:hover {
        color: @gray;
      }
    }
  }

  .text-success {
    &.lesson__hint {
      // background-color: lighten(@brand-success, 48%);
      border-left: 5px solid @brand-success;

    }
  }

  .text-danger {
    &.lesson__hint {
      // background-color: lighten(@brand-danger, 48%);
      border-left: 5px solid @brand-danger;
    }
  }

  .hint-text-success {
    &.lesson__hint {
      // background-color: lighten(@brand-success, 48%);
      border-left: 5px solid @brand-success;
      color: @gray;
    }
  }

  .hint-text-danger {
    &.lesson__hint {
      // background-color: lighten(@brand-success, 48%);
      border-left: 5px solid @brand-danger;
      color: @gray;
    }
  }

  .hint-result {
    vertical-align: middle;
    line-height: 190%;
    margin-bottom: 15px;
    &.success {
      border-bottom: 1px dotted @brand-success;
    }
    &.danger {
      border-bottom: 1px dotted @brand-danger;
    }

    &__label {
      color: @gray-dark;
      vertical-align: middle;
    }

    &__icon {
      font-size: 190%;
      vertical-align: middle;
      padding-right: 10px;
      &.success {
        color: @brand-success;
      }
      &.danger {
        color: @brand-danger;
      }
    }
  }

  .hint-result__label {
    color: @gray-dark;
      vertical-align: middle;
    }

  &__content {
   // .box-content();
    margin: 1.5rem 0 1.5rem 0;

    .no-float {
      float: none!important;
    }
    &-pages {
      font-size: 110%;
      text-align: center;
      position: relative;
      width: 6rem;
      white-space: nowrap;
      margin: 3em 0 0 auto;
    }
  }
  &__start &__content {
    margin-right: 0;
  }

  &__category-type {
    font-size: 1rem;
    margin-top: 1.5rem;
  }

  &__category-name {
    margin: 1.5rem auto;
    padding: 15px 48px 15px 15px;
    color: @gray;
    opacity: 0.8;

    &:not(.disabled):hover {
      color: #222!important;
    }

    i.fa-caret-down, i.fa-caret-up {
      transition: color .2s;
      margin: -4px 1rem;
      top:  14px;
      right: 8px;
      position: absolute;
      font-size: 36px;
    }

    &.disabled {
      padding-right: 150px;
    }

    &.disabled .btn {
      position: absolute;
      top: 10px;
      bottom: 10px;
      right: 8px;

      @media (max-width: @screen-sm-max) {
        top: 7px;
        bottom: 7px;
        right: 5px;
      }
    }

    i.fa-bell-o {
      margin-right: 0.5rem;
    }

    &__clickable {
      cursor: pointer;
    }

    &__opened {
      opacity: 1;
    }

    &--no-padding {
      padding: 0;
    }

    &__wrapper--group & {
      margin-top: 0;
    }
    &__wrapper--group {
      margin-bottom: 1rem;

      .container {
        width: auto;
      }
    }

    &__wrapper {
      position: relative;
      background-color: white;
      border-radius: 6px;
      transition: all .6s;

      h4, h5 {
        margin-bottom: 0;
      }

      &.open {
        background-color: @brand-success;

        & > h4, & > h5 {
          color: #ffffff !important;
          margin-bottom: 1.5rem;
          &:hover {
            color: #ffffff !important;
          }
        }
      }
    }

    &__wrapper--group.open {
      background: darken(@brand-success, 10%); // #408261;
    }

    &__wrapper--order {
      background-color: #bbbbbb;
    }
    &__wrapper--order.open {
      background-color: #999999;
    }
    &__wrapper--order&__wrapper--group.open {
      background-color: #777777;
    }

    &__badge {
      position: absolute;

      background: @brand-bad;
      color: #fff;
      font-weight: bold;
      text-align: center;

      @badge-size: 1.2; //rem

      width: @badge-size * 1rem;
      height: @badge-size * 1rem;
      border-radius: @badge-size * 1rem;
      line-height: @badge-size * 1rem;
      font-size: @badge-size * 0.8em;
      left: @badge-size * -2em;
      margin-left: 15px;
      top: @badge-size * 0.1em;

      @media (max-width: @screen-xs-max) {
        top: -0.8em;
        left: -0.9em;
      }
    }
  }

  &__category-progress {
    @media (max-width: @screen-sm-min){
      display: none;
    }
    text-transform: lowercase;
    padding-left: 0.2rem;
    margin-right: -0.2rem;
    color: inherit;
    position: relative;

    .delimiter {
      margin: 0 0.7rem;
    }

    // TODO: 6artisans
    // &__due-date {
    //   position: absolute;
    //   margin-left: -200px;
    //   width: 200px;
    //   line-height: 150%;
    //   padding-right: 0.3rem;
    //   text-align: right;
    // }
  }

  .questions {
    margin-left: 2rem;
    margin-right: 2rem;

    @media (max-width: @screen-sm-max) {
      margin-left: 0.5rem;
      label {
        font-size: 115%;

        input[type=radio] {
          width: 30px;
          &:before, &:after {
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    &__question {
      // margin-top: 0.61rem !important;
      &[type=text] {
        -webkit-box-shadow: inset 0 0 0 2px #999;
        box-shadow: inset 0 0 0 2px #999;
        font-size: 1.8em;
        height: 3rem;
        margin-left: -2rem;
        @media (max-width: @screen-sm-max) {
          margin-left: -0.5rem;
        }

        &:focus {
          -webkit-box-shadow: inset 0 0 0 3px @blue;
          box-shadow: inset 0 0 0 3px @blue;
        }
      }
    }

    .form-group {
      margin-bottom: 0;
    }

    .help-block {
      margin-left: 1rem;
      display: inline-block;
    }

    .radio {
      display: inline-block;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border-color: @questions-border;
      border-style: solid;
      border-radius: 5px;
      border-width: 2px;
      width: 100%;
      padding: 1rem;
      padding-left: 2rem;
      transition: all 0.25s linear;

      .control-label{
        width: 100%;
      }

      .help-block{
        margin-left: 2rem;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    /* On mouse-over */
    .radio:hover {
      border-color: @questions-selected;
      transition: all 0.25s linear;
    }

    .radio--checked {
      border-color: @questions-selected;
      border-width: 3px;
      transition: all 0.25s linear;
    }
    // hide radio input, we have custom checkmart
    .radio input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      left: 0.8rem;
      // position to the center
      top: 50%;
      transform: translate(0, -50%);

      height: 36px;
      width: 36px;
      background-color: white;
      border-radius: 50%;
      border-style: solid;
      border-color: @questions-border;
      border-width: 2px;
      transition: all 0.25s linear;
    }

    /* On mouse-over */
    .radio:hover input ~ .checkmark {
      border-color: @questions-selected;
      transition: all 0.25s linear;
    }

    /* When the radio button is checked,*/
    .radio input:checked ~ .checkmark {
      background-color: white;
      border-color: @questions-selected;
      transition: all 0.25s linear;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .radio input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .radio .checkmark:after {
     	top: 2px;
    	left: 2px;
    	width: 28px;
    	height: 28px;
    	border-radius: 50%;
    	background: @questions-selected;
      transition: all 0.25s linear;
    }

    // question box - wrong answer
    .text-danger{
      .radio {
        border-color: @brand-danger;
        color: @content-text;
        border-width: 3px;
      }
      .radio input:checked ~ .checkmark {
        border-color: @brand-danger;
      }
      .radio .checkmark:after {
       background: @brand-danger;
     }
    }

    // question box - correct answer
    .text-success{
      .radio {
        border-color: @brand-success;
        color: @content-text;
        border-width: 3px;
      }
      .radio input:checked ~ .checkmark {
        border-color: @brand-success;
      }
      .radio .checkmark:after {
       background: @brand-success;
     }
    }

    .questions__images{
      display: table;
      width: 100%;
      // for width enough screens show in two columns
      @media screen and (min-width: @screen-sm-min) {
        border-spacing: 10px; // space between rows
      }

      .questions__images__row{
        // for width enough screens show in two columns
        @media screen and (min-width: @screen-sm-min) {
          display: table-row;
          width: auto;
        }

        clear: both;
        width: 100%;

      }

      .questions__images__cell {
        width: 100%;
        margin-bottom: 10px;
        // for width enough screens show in two columns
        @media screen and (min-width: @screen-sm-min) {
          float: left; /* fix for  buggy browsers */
          display: table-cell;
          width: 48%;
          margin-bottom: 0;
        }
        margin-left: 1%;
        margin-right: 1%;
        height: 100%;
        line-height: 100%;
        aspect-ratio: 1 / 1;

        .image{
          height: 100%;
          width: 100%;
          object-fit: cover;

          .form-group{
            height: 100%;
            width: 100%;
          }

          .radio{
            height: 100%;
            text-align: center;
            padding: 0;
            .control-label{
              height: 100%;
              padding: 0 !important;
              .lesson__image-container {
                margin: 10px;
                display: flex;
                justify-content: center;
                align-content: center;
                flex-wrap: nowrap;
                flex-direction: column;
              }
            }
            .help-block {
              margin: 0;
              margin-bottom: 10px;
            }
          }
          .checkmark{
            position: absolute;
            opacity: 0;
          }
        }
      }
    }
  }

  &__hint {
    padding-left: 20px;
    margin-bottom: 20px;
  }

  &__done {
//    padding-right: 20px;
//    text-align: center;
    position: absolute;
    left: 100%;
    top: 5px;
    margin-left: -5px;
  }

  &__pages {
    line-height: 2em;
  }

  &__assign {

    &__type {
      color: @gray-darker;
      overflow: hidden;

      text-align: center;

      &__dummy-title {
        .text-uppercase;
        font-size: 1.7em;
        margin: 10px 0 5px 0;
      }

      &__dummy-info {
        font-size: 0.85em;
        min-height: 2em;
      }

      .tag {
        width: 10rem;
        border-bottom: 1px solid @gray;
        padding-bottom: 25px;
        margin: 10px auto 15px auto;
        position: relative;
      }

      &__description {
        display: block;
        margin-top: 15px;
        font-size: 0.9em;
        line-height: 1.3em;
      }

      @media (max-width: @screen-xs-max) {
        width: 100%;
        height: auto;

        .tag {
          width: 100%;
        }
      }

      i {
        font-size: 200%;
      }
    }

    &__start {
      margin-left: 0.8rem;
    }

    &__defer {
      .btn.bordered {
        margin: 0.2rem 0rem 0.5rem 0.8rem !important;
      }
    }

    &__stored_selection {
      margin-left: 0.9rem;
    }

    &__store_selection_actions {
      padding-left: 1rem;
     // margin-bottom: 1rem;



        div{
          display: inline;
        }

        .form-group{
          margin-bottom: 0;
        }

        // Animations to change [Save Selection] btn into input with [Save] btn
        @keyframes fade-in {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }

        @keyframes fade-in-right {
          from {
          //  opacity: 0;
            transform: translateX(-150px);
          }
          to {
          //  opacity: 1;
            transform: translateX(0);
          }
        }

        .btn{
          padding: 6px 10px!important;
          margin-bottom: 5px;
        }

        .fadein-anim{
          animation: fade-in-right ease 0.5s forwards;
        }

        .form-control{
          width: inherit;
          display: inline;
          margin-right: 1rem;
          margin-top: 5px;
          opacity: 0;
          animation: fade-in ease 0.5s forwards;
          animation-delay: 0.3s;
        }

        .btn.bordered.remove{
          border-color: darken(@brand-danger, 10%);
          color: darken(@brand-danger, 10%)!important;
          margin-left: 1rem;

          &:hover,
          &:focus,
          &.active {
            color: #fff !important;
            background-color: @brand-danger!important;
          }
        }
    }


  }

  .text-input {
    textarea {
      width: 100%;
      height: 11em;
      resize: none;
      font-size: 1.3em;
      padding: 0.5em 0.8em;
    }
  }

  &__answer-info {
    margin: -0.5rem 0 0.5rem 0;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    i {
      margin-right: 0.75rem;
    }

    p {
      margin: 0;
      &.info {
        font-size: 1.0em;
        line-height: 1.4;

        @media (max-width: @screen-sm-max) {
          font-size: 0.9em;
        }
      }
    }

    &__divider {
      margin-left: 0.7rem;
      margin-right: 0.7rem;
      @media (max-width: @screen-sm-max) {
        margin-left: 0.4rem;
        margin-right: 0.4rem;
      }
    }
  }

  .embed {
    iframe {
      width: 100%;
      aspect-ratio: ~'16/9';
      border: 0;
    }
  }
}

.visualization__box--fullscreen .lesson__answer-info {
  padding-left: 2%;
}

.tab-pane {
  .lesson__category-name {
    margin: 2rem 0.8rem;
  }
}

.filter {
  margin: 0.5rem 0;
  line-height: 3.5rem;

  .clear-button {
    width: 100%;
    vertical-align: middle;
    line-height: 2.3rem;
  }
}

.lessons-type-filter {
  button {
    text-transform: capitalize;
    outline: none !important;

    &.btn-secondary, &.btn-secondary.active {
      background: #fff;
      border: 1px solid #ddd;
    }

    i {
      margin-top: 0.2rem;
      font-size: 140%;
    }
  }
}

.welcome-panel {
  background-color: #fff;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  height: 0px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  transition: all 300ms;

  .close {
    position: absolute;
    top: 6px;
    right: 6px;
    margin-bottom: 0px;

    a {
      color: #666;
    }
  }
  h3 {
    color: @green;
  }
  h4 {
    color: #0a6ebd;
    margin-bottom: 2em;
  }
  .btn {
    margin-top: 3em;
  }

  &.open {
    margin-bottom: 20px;
    height: 30em;
    padding: 1em 2em;
  }

  &--large.open {
    height: 40em;
  }
}

.lesson__category-progress {
  text-transform: lowercase;
  padding-left: 0.2rem;
  margin-right: -0.2rem;
  color: inherit;
}

.lesson-tags {
  > .lesson-tag {
    &:after {
      content: '|';
      margin-left: 1ex;
      margin-right: 1ex;
    }
    &:last-child:after {
      content: '';
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.status-good {
  color: @brand-success;
}

.status-normal {
  color: @brand-warning;
}

.status-bad {
  color: @brand-bad;
}

.tag {
  display: inline-block;
  text-align: center;
  padding: 0 0.7rem;
  vertical-align: middle;

  &__blue {
    color: @blue-base;
  }

  &__xl {
    font-size: 250%;
  }

  &__description-tag{
    position: absolute;
    top: 10px;
    right: 0;

    i {
      font-size: 200%;
    }
  }

  &__no-padding {
    padding: 0;
  }

  &__header {
    padding: 0.2rem 0;
    font-size: 150%;
    display: block;
    @media (max-width: @screen-md-max) { font-size: 130%; }
    @media (max-width: @screen-sm-max) { font-size: 150%; }
  }

  &__full-height {
    font-size: 150%;
    padding: 0.2rem 0;
    line-height: 280%;
    display: inline-block;
    text-align: right;

    &--normal-font {
      font-size: 100%;
      line-height: 400%;
    }

    &--normal-lines {
      line-height: 200%;
    }
  }

  &__content {
    font-size: 100%;
    padding: 0.2rem 0;
    display: block;
    line-height: 18px;
  }

  &__green {
    color: @brand-success;
  }

  &__good {
    .status-good;
  }

  &__normal {
    .status-normal;
  }

  &__bad {
    .status-bad;
  }

  &__slim {
    max-width: 70%;
  }

  &__left &__full-height {
    text-align: left;
  }

  a, a:hover, a:focus {
    text-decoration: none !important;
  }
}

.hr {
  &__dark {
    border-color: @gray;
  }
}

.auxiliary {
  &__open {
    position: fixed;
    bottom: 1.8em;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 100;
    text-align: center;
    pointer-events: none;

    a {
      pointer-events: all;
      text-decoration: none;
      font-weight: bold;

      i {
        display: block;
        font-size: 2.5em;
        width: 1.6em;
        height: 1.6em;
        line-height: 1.6em;
        border-radius: 1em;
        margin: 0 auto 0.2em auto;
        color: white;
        background: @link-color;
      }

      &:hover i {
        background: @link-hover-color;
      }
    }
  }

  &__visualization {
    width: 100%;
    max-width: 980px;
    margin: 0 auto;

    @media (max-width: @screen-xs-max) {
      margin-top: 50px;
    }
  }
}
.hint-modal__hint-frame {
  z-index: 110 !important;
  height: 99% !important;

  iframe {
    max-height: 25rem;
  }

  .hint-modal__continue {
    color: @brand-success;
    font-size: 3em;
    position: absolute;
    top: 15px;
    right: 40px;

    @media (max-width: @screen-xs-max) {
      top: 5px;
      right: 0px;
    }

    &:hover {
      color: darken(@brand-success, 10%);
    }
  }

  .xmodal__close.hint-modal__incorrect {
    color: @blue-base;

    &:hover {
      color: darken(@blue-base, 10%);
    }
  }
  .xmodal__close.hint-modal__correct {
    right: 90px;

    @media (max-width: @screen-xs-max) {
      right: 50px;
    }
  }
}

// React modal overhaul
.modal__overlay {
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.modal__content {
  .with-shadow();
  position: absolute;
  top: 75px;
  left: 150px;
  right: 150px;
  bottom: 75px;
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

@media (max-width: 768px) {
  .modal__content {
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    padding: 10px;
  }
}

// Fullscreen overhaul
.lesson-fullscreen {

  &.fs--open {
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: @jumbotron-bg;

    & .fs-inner {
      height: 100%;
      overflow: auto;
    }

    // Polyfill
    &.fs--polyfill {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }
}


// Lesson fullscreen styles
.lesson-fullscreen.fs--open {
  padding-top: 1rem;

  .lesson {
    max-width: 90%;

    p {
      max-width: 40rem;
    }

    p.lesson__image-container {
      max-width: 100%;
    }

    &__title {
      color: #eeeeee;
    }
  }
}

// Lesson page pre ucitela

.teacherLesson {
  &__assign {
    &-button {
      width: 100%;
      margin-top: .5rem;
      .fa {
        padding-top: .3rem;
      }
      text-transform: lowercase;
    }
  }

  &__btn {
    font-size: 200%;
    float: left;
    margin-right: 1rem;

    &:hover {
      color: @gray-dark !important;
    }
    color: @gray !important;
  }

  &__tag {
    float: left;
    margin: 0.35rem 0.5rem 0.35rem 0;
    height: 1.7em;
    line-height: 1.4em;
  }
}

.lessons {
  &__datepicker {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 62px 62px 62px 62px 62px 62px 62px;
    column-count: 7;
    grid-row-gap: 12px;
    grid-column-gap: 12px;

    &::before {
      display: none;
    }

    &__day {
      text-align: center;
      text-transform: capitalize;
    }

    &__tag {
      overflow: hidden;
      a {
        margin: 0;
        border-radius: 4px!important;
        background-color: rgba(0,0,0,0.06);
        color: @text-color;
        padding: 12px 16px!important;
        width: 100%;
        display: block;
        text-align: center;
        text-decoration: none!important;
        &:hover {
          background-color: rgba(0,0,0,0.14) !important;
          color: @text-color;
        }
      }
      &.disabled, &.disabled:hover {
        a {
          background-color: transparent !important;
          border-color: transparent !important;
          cursor: not-allowed;
          color: #999;
        }
        a:hover, a:focus {
          color: #999;
          text-decoration: none;
          background-color: transparent!important;
        }
      }

      &.active, &.active:hover {
        a {
          background-color: @brand-success!important;
          color: white!important;
        }
      }
    }
  }

  &__month-selector {
    >span {
      padding: 0 12px;
      width: 240px;
    }

    a {
      color: #888;

      &:hover {
        color: #444;
      }
    }
  }

  &__modal {
    //padding-left: 1.3rem;
    min-height: 25rem;
    &-label {
      display: inline-block;
      margin: 0.5rem 1rem 0.5rem 0;
      font-size: 1rem;
      line-height: 1.5rem;
      height: 1.8rem;
      &-add {
        cursor: pointer;
      }
      &-amount {
        cursor: pointer;
        margin-right: 0;
      }
      &.banned {
        opacity: 0.3;
        cursor: default;
        a {
          cursor: default;
        }
      }
    }
    &-wrapper {
      min-height: 2.8rem;
      margin-bottom: 1rem;

      &-select {
        margin-left: -25px;
        margin-top: -25px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
    &-frame {
      border: 1px solid @gray;
      width: 30%;
      min-height: 220px;
      min-width: 300px;
      padding: 22px;
      flex-grow: 1;
      margin-left: 25px;
      margin-top: 25px;
      cursor: pointer;
      color: @gray-dark;
      text-decoration: none;

      &:not(.disabled):hover, &:not(.disabled):focus {
        border: 4px solid @gray;
        padding: 19px;

      }
      &--active {
         border: 4px solid @brand-success!important;
         padding: 19px;
       }
    }
    &-genschooltoken,
    &-addclass {
      display: inline-block;
      padding-right: 5rem;
      font-size: 1rem;
      min-height: 1.7rem;
      vertical-align: middle;
      margin: 0.4rem 0 0 0;

      .form-group {
        display: inline-block;
      }
      input[type=text] {
        display: inline-block;
        width: 12rem;
        line-height: 1.1rem;
      }
      .btn {
        margin-left: 0.5rem;
        line-height: 1rem;
      }

      input[type=text], .btn {
        height: 1.7rem;
        vertical-align: top;
      }

      .form-group .form-control-feedback {
        top: -4px;
      }
    }
    &-link {
      input.form-control[readonly] {
        background-color: #fff !important;
        line-height: 1.1rem;
        height: 1.7rem;
        vertical-align: top;

        @media (min-width: @screen-sm-max) {
          width: auto;
        }
      }
    }

    h5 {
      margin-top: 3rem;

      &.small-space {
        margin-top: 1rem;
      }
    }

    h4 {
      &.large-space {
        margin-bottom: 3rem;
      }
    }

    p {
      font-size: 1rem;
    }

    &-addtask {
      margin-top: 1rem;
      margin-bottom: 2rem;

      &[disabled] {
        background: @gray-light !important;
      }
    }

    &__type {
      display: inline-block;
      color: @gray-darker;
      margin: 0.5rem 1rem 0.5rem 0;
      padding-bottom: 0.5em;
      padding-top: 0.5em;
      border-radius: 6px;
      background-color: rgba(0,0,0,0.06);

      &:not(.disabled):hover, &:not(.disabled):focus {
        background-color: rgba(0,0,0,0.14);
        color: @gray-darker;
      }

      &--active {
        &, &:not(.disabled):hover, &:not(.disabled):focus {
          color: @white!important;
          background-color: @green!important;
        }
      }

      &--primary {
        color: @white !important;
        background-color: @brand-primary;
        &:hover, &:focus {
          background-color: darken(@brand-primary, 6%);
        }
      }
    }
  }

  &--only .school-classes {
    margin-bottom: @line-height-computed;
  }

  .school-classes {
    @media (max-width: @screen-xs-max) {
      margin-top: -0.5rem;
    }
  }

  &__no-class {
    .text-center;
    margin: 3em 0;
  }

  &__no-class-button {
    .text-center;

    .btn {
      font-size: 2em;
    }
  }
}
.lessons__aux-modal {
  &__visualization {
    width: 100%;
    max-width: 980px;
  }

  &__favourite {
    font-size: 2em;
    position: absolute;
    top: 25px;
    right: 110px;
  }
}

.task-cart {
  position: relative;
  overflow: visible !important;
  margin-bottom: 1rem !important;

  &.disabled {
    cursor: not-allowed;

    * {
      color: @gray-light !important;
    }
  }

  &__badge {
    position: absolute;

    background: @brand-bad;
    color: #fff;
    font-weight: bold;
    text-align: center;

    @badge-size: 1.2; //rem

    width: @badge-size * 1rem;
    height: @badge-size * 1rem;
    border-radius: @badge-size * 1rem;
    line-height: @badge-size * 1rem;
    font-size: @badge-size * 0.8em;
    left: @badge-size * -2em;
    top: @badge-size * 2em;

    @media (max-width: @screen-sm-max) {
      top: 0.2em;
      left: 0.2em;
    }
  }

  &__cancel {
    display: none;
  }

  &:hover &__cancel {
    display: block;
    position: relative;
    z-index: 2;
  }

  &__notifications {
    position: absolute;
    right: 0.9rem;
    top: 0.8rem;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 0.9rem;
    line-height: 2rem;
    text-align: center;
    color: #fff;
    background: @brand-success;
  }

  &__type {
    position: absolute;
    right: 0.9rem;
    top: 0.8rem;
    width: 1.8rem;
    height: 1.8rem;
    line-height: 2rem;
    text-align: center;
    font-size: 1.5em;
  }

  &__count {
    height: 2.2em;

    line-height: 1.8rem;
    .pull-left;

    i {
      margin-right: 0.5rem;
    }
  }

  &:hover &__notifications {
    display: none;
  }

  .lesson-name {
    margin-top: 0.15rem;
    margin-bottom: 0.3rem;

    @media (max-width: @screen-xs-max) {
      margin-bottom: 1.3rem;
    }

    @media (min-width: @screen-xs-max) {
      &__no-tags {
        height: 3.3rem;
        vertical-align: middle;
        display: table-cell;
      }
    }
  }
}

.annotation {
  color: @brand-primary;
  position: relative;

  & &-wrapper {
    z-index: 10;
    visibility: hidden;
    opacity: 0;

    transition-property: opacity;
    transition-duration: 500ms;
    transition-timing-function: cubic-bezier(0.175, 0.665, 0.320, 1);

    display: block;
    font-style: normal;
    position: absolute;
    width: 180px;
    margin-left: -90px;
    @media (min-width: (@screen-lg-min + 140)) {
      width: 360px;
      margin-left: -180px;
    }
    margin-top: 7px;
    left: 50%;
    top: 100%;
    text-align: center;

    .annotation-content {
      display: inline-block;
      margin: auto;
      color: #222222;
      background-color: #bbbbbb;
      border-radius: 5px;
      padding: 5px;
    }
  }

  & &-wrapper::before {
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -5px;
    margin-top: -7px;
    content: '';
    border-width: 0 5px 7px 5px;
    border-style: solid;
    border-color: transparent;
    border-bottom-color: #bbbbbb;
  }

  &:hover &-wrapper {
    visibility: visible;
    opacity: 1;
  }
}

.next {
  &, &:focus, &:active {
    color: @brand-success;
  }

  &:hover {
    color: darken(@brand-success, 10%);
  }

  &.disabled {
    color: @gray-light;
  }
}

.cancel {
  &, &:focus, &:active {
    color: @brand-danger;
  }

  &:hover {
    color: darken(@brand-danger, 10%);
  }
}

.disabled {
  color: @gray-light!important;
  cursor: not-allowed;
}

.lesson-with-task,
.lesson-without-task,
.lesson-unassigned {
  border-left: 10px solid transparent;
}

.lesson-with-task {
  border-color: @brand-warning;

  .task-deadline {
    color: @brand-warning;
  }

  &--deferred {
    border-color: @brand-primary;

    .task-deadline {
      color: @brand-primary;
    }
  }
}

.lesson-without-task {
  border-color: #ccc;
}

.lesson-unassigned {
  border-color: #fff;
}

.task-cart {
  .col-md-1 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .panel-body {
    overflow: hidden;
    position: relative;
  }

  .lesson-description {
    position: absolute;
    font-size: .9em;
    top: 100%;
    z-index: 2;
    padding: 4px 12px;
    left: 15px;
    right: 15px;
    transition: top .6s;
    background-color: white;
    height: 100%;
    box-sizing: border-box;

    div {
      height: 100%; // to make align center work
    }
    .point{
      display : flex;
      align-items : center; // center when the text is short
      padding-right: 80px; // to not overlap with arrow
    }

    .tag {
      @media (max-width: @screen-sm-max) {
        visibility: none;
      }
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 96%;
      height: 18px;
      background: -webkit-linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      background-image: -moz-linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      background-image: -o-linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      background-image: linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      background-image: -ms-linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }

  .panel-body:hover{
    .lesson-description {
      top: 0%;
    }
  }
}

.lesson-description {
  .tag {
    @media (max-width: @screen-sm-max) {
      visibility: hidden;
    }
  }
}

.lesson-preview {
  h4 {
    margin-top: 2rem;
  }

  .panel {
    margin-top: 1rem;
    background-color: inherit;
    color: @content-text;
  }

  .panel-body {
    padding: 0px;
  }

  &__type {
    color: @gray-light;
    font-size: 1.3em;
    margin-top: -0.4rem;
    margin-bottom: 0.8rem;

    &&--task {
      color: @brand-warning;
    }
  }

  &__solution {
    margin: 15px 0;
    font-size: 1.1em;
  }
}

.lesson__content {
  color: @content-text;
}

.lesson__block {
  background-color: white;
  display: block;
  box-sizing: border-box;
  border-radius: @border-radius-base;
  border-style: solid;
  border-color: @block-border-color;
  border-width: 2px;
  padding: 1.5rem 3rem;
  margin-bottom: 1.5rem;
  @media (max-width: @screen-sm-max) {
    padding: 1rem 1.5rem;
  }
  @media (min-width: @screen-md-min) {
    margin-right: 6rem;
  }

  &--green{
    //border-width: 0px;
    border-left: 9px solid green;
    border-top-left-radius: 0px;
  }

  &--red{
    //border-width: 0px;
    border-left: 9px solid red;
    border-top-left-radius: 0px;
  }

  &--blue{
    //border-width: 0px;
    border-left: 9px solid blue;
    border-top-left-radius: 0px;
  }
}


.lesson__block .katex {
  background-color: #f1f1f1;
  color: #5c5c5c;
  white-space: nowrap;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 4px;
  padding-left: 4px;
  line-height: 180%;
  display: inline-block;
  .base {
    line-height: 100%;
  }
}

.assign_step_header {
  h5 {
    font-size: 1.7rem;
  }
  &__news{
    color: @brand-info;
    font-size: 1.55rem;
    font-weight: bold;
  }
}

.assign_students_for_exam {
  &--body{
    transition: all 300ms;
  }

  .checkbox{
    &--checked{
      label{
        font-weight: bold;
        color: @green;
      }
    }
  }
}

.create_task_error {

  font-size: 1.1rem;

  div {
    margin-bottom: 1rem;
  }

  &__student {
    font-style: italic;
    margin-right: 0.8rem;
  }

  .create_task_error_btns {

    margin-top: 3rem;

    button {
      margin-right: 4rem;
    }
  }
}

.lessons-view-switcher {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  &__main, &__aux {
    margin-bottom: 1rem;
    width: 100%;

    @media (min-width: @screen-md-min) {
      .xshadow;
      background: white;
      padding: 10px;
      border-radius: 15px;
      width: 49%;
      height: 86px;

      &:first-of-type:last-of-type {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &__main {
    display: grid;
    position: relative;
    grid-template-columns: 1fr 1fr;
    &--single {
      grid-template-columns: 1fr;
    }

    select {
      display: block;
      .xselect;
      font-size: 1.5em;
      @media (max-width: @screen-xs-max) {
        font-size: 1.2em;
      }
    }

    &--notification::after {
      content: '';
      display: block;
      position: absolute;
      background: @brand-bad;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      top: -5px;
      left: -5px;
      @media (min-width: @screen-md-min) {
        top: 5px;
        left: 5px;
      }
    }
  }


  &__views {
    display: flex;
    justify-content: right;
    align-items: center;
    font-size: 1em;
    a {
      margin-top: 2px;
      margin-bottom: 2px;
      flex-direction: column;
    }
    a:not(:first-of-type) {
      margin-left: 2px;
    }
    @media (min-width: @screen-sm-min) {
      font-size: 1.2em;
      a:not(:first-of-type) {
        margin-left: 5px;
      }
    }
    &__custom {
      padding: 5px 10px !important;

      & > span {
        font-size: 0.9em;
      }
    }
  }

  &__aux {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 10px;
  }
}

.lessons-list {
  margin: auto;
  max-width: 500px;
  padding-top: 2rem;

  &__subcategory {
    margin-bottom: 2rem;

    &__title {
      font-weight: bold;
      border-bottom: 2px solid white;
      font-size: 1.2em;
      padding: 15px;
      color: lighten(@xcontrol-fg-base, 10%);

      &--group {
        font-size: 1em;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    &__content {
      border-bottom: 2px solid white;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: left;
      align-items: top;
    }
  }

  &__lesson {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: 10px;
    border-bottom: 2px solid white;
    margin-bottom: -2px;
    width: 20%;
    @media (max-width: @screen-xs-max) {
      width: 25%;
    }
    &, &:hover, &:active {
      text-decoration: none !important;
    }
    &__number {
      aspect-ratio: 1;
      width: 100%;
      background: white;
      box-shadow: 0px 2px 4px 1px #00000040;
      border-radius: 100%;
      color: @brand-success;
      font-size: 2em;
      font-weight: 900;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 6px solid white;
    }
    &--next &__number {
      color: @xcontrol-fg-base;
      border-color: @brand-success;
    }
    &__score {
      display: flex;
      flex-direction: row;
      background: white;
      border-radius: 3px;
      box-shadow: 0px 1px 4px 0px #00000040;
      padding: 0 2px;
      margin-top: -20px;
      font-size: 0.9em;
      @media (max-width: @screen-xs-max) {
        margin-top: -15px;
        font-size: 0.8em;
      }
      &__star {
        color: #F2F2F2;
        &--obtained {
          color: #FF9300;
        }
      }
    }
    &--next &__score {
      visibility: hidden;
    }
  }

  &__no-next-lesson {
    padding: 15px 10px;
    width: 40%;
    @media (max-width: @screen-xs-max) {
      width: 50%;
    }

    &__content {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      background: #F8F6F2;
      box-shadow: 0px 1px 2px 0px #00000040;
      border-radius: 15px;
      color: #363636;
    }
  }
}

.lessons-list-tags {
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;

  @media (min-width: @screen-md-min) {
    padding: 10px;
  }

  .xbutton {
    height: 64px;
    @media (min-width: @screen-md-min) {
      height: 86px;
    }
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.lessons-view-switcher__aux, .lessons-list-tags {
  .xbutton {
    flex-direction: column;
    font-weight: bold;
    padding: 5px 0;
    i {
      margin: 2px 0 8px 0;
    }
    span {
      white-space: nowrap;
      text-align: center;
      line-height: 110%;
      font-size: 0.85em;
      @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
        font-size: 0.75em;
      }
      @media (max-width: @screen-sm-max) {
        font-size: 0.8em;
      }
    }
  }
}

.lessons-path {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &__start-link {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    &, &:hover, &:active {
      text-decoration: none !important;
    }
  }

  &__start {
    background: @brand-success;
    color: #F8F6F2;
    border-radius: 15px;
    text-align: center;
    min-width: 168px;
    max-width: 220px;
    font-size: 2em;
    font-weight: bold;
    padding: 1.2rem 2rem;
    position: relative;
    margin-bottom: 2rem;
    margin-top: 2rem;
    &::after {
      content: '';
      display: block;
      background: @brand-success;
      width: 30px;
      height: 30px;
      transform: rotate(45deg);
      position: absolute;
      bottom: -15px;
      left: 50%;
      margin-left: -15px;
      z-index: -1;
    }

    &__subtext {
      font-size: 80%;
      font-weight: 400;
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }

  @media (min-width: @screen-md-min) {
    &--with-challenge {
      padding-left: 45%;
      @media (min-width: @screen-lg-min) {
        padding-left: 35%;
      }
    }

    &--challenge&--floating {
      position: relative;
      padding-right: 45%;
      @media (min-width: @screen-lg-min) {
        padding-right: 35%;
      }
    }

    &--challenge&--floating &__start-link {
      position: absolute;
      z-index: 10;
      top: 50px;
    }
  }

  &--challenge &__start {
    background: @brand-danger;
    padding: 0.8rem 2rem;
    &::after {
      background: @brand-danger;
    }
  }

  &__lesson {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    position: relative;
    &, &:hover, &:active {
      &, * {
        text-decoration: none !important;
      }
    }
    &__icon {
      aspect-ratio: 1;
      width: 80px;
      background: white;
      box-shadow: 0px 2px 4px 1px #00000040;
      border-radius: 100%;
      color: @brand-primary !important;
      font-size: 2em;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 60%;
        max-height: 60%;
      }
    }
    &--next &__icon {
      border: 8px solid @brand-success;
      width: 150px;
      font-size: 3em;
    }
    &__score {
      position: relative;
      background: white;
      border-radius: 3px;
      box-shadow: 0px 1px 4px 0px #00000040;
      padding: 0 2px;
      margin-top: -10px;
      font-size: 1.2em;
      &__star {
        color: #F2F2F2;
        &--obtained {
          color: #FF9300;
        }
      }
    }

    &:not(&--next) {
      &::before {
        content: '';
        display: block;
        height: 65px;
        width: 15px;
        border-radius: 15px;
        background: white;
        box-shadow: 0px 4px 4px 0px #00000040;
        margin: 1rem 0;
      }

      &:nth-of-type(2n + 1) {
        margin-left: 80px;
        &::before {
          transform: rotate(-20deg);
          margin-left: -60px;
        }
      }
      &:nth-of-type(2n) {
        margin-right: 80px;
        &::before {
          transform: rotate(20deg);
          margin-right: -60px;
        }
      }
      &:last-of-type {
        margin-left: 0;
        margin-right: 0;
      }
      &:last-of-type:first-of-type {
        &::before {
          transform: none;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }

  &__challenge {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    position: relative;
    aspect-ratio: 1;
    width: 150px;
    box-shadow: 0px 2px 4px 1px #00000040;
    border-radius: 100%;
    background: conic-gradient(
      @brand-danger 0deg var(--angle, 0deg),
      #EFEDE9 var(--angle, 0deg) 360deg
    );
    margin-bottom: 1rem;
    &, &:hover, &:active {
      &, * {
        text-decoration: none !important;
      }
    }
    &--finished &__icon {
      color: @brand-success !important;
    }
    &__icon {
      aspect-ratio: 1;
      width: 120px;
      background: white;
      border-radius: 100%;
      color: #363636 !important;
      font-size: 5em;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__start-link.disabled &__start {
    &, &::after {
      background: #B3B1AD;
      color: #363636;
    }
  }
  &__start-link.disabled &__challenge {
    background: #B3B1AD;
  }

  &__welcome-arrow {
    margin-top: 2rem;
    text-shadow: 0px 4px 4px #00000040;
    font-size: 4em;
    color: white;
  }

  &__box {
    margin-top: 2rem;
    &--space-bottom {
      margin-bottom: 1rem;
    }
    background: white;
    border-radius: 27px;
    box-shadow: 0px 2px 4px 0px #00000040;
    padding: 10px 20px;
    width: 100%;
    max-width: 360px;
    position: relative;
    overflow: hidden;

    &__title {
      font-size: 1.5em;
      font-weight: bold;
      text-align: center;
      margin: 10px 10px 15px 20px;

      i {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        box-shadow: 0px 2px 4px 1px #00000040;

        img {
          max-width: 28px;
          max-height: 28px;
        }
      }
    }

    &--notice {
      max-height: 600px;
    }
    &--overlap {
      margin-top: -260px;
      @media (min-width: @screen-md-min) {
        margin-top: -270px;
      }
    }
    &--notice &__text {
      min-height: 205px;
    }
    &--notice&--hidden {
      margin-top: 0;
      max-height: 0;
      padding: 0;
      opacity: 0;
      transition:
        margin-top 200ms ease-out,
        max-height 200ms ease-out,
        padding 200ms ease-out,
        opacity 200ms ease-out;
    }

    &--notice &__title {
      font-size: 1.1em;
    }

    &__divider {
      text-align: center;
      margin: 10px 0;
      color: #11D1A7;
    }

    &__rect-icon {
      width: 40px;
      height: 40px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      box-shadow: 0px 2px 4px 1px #00000040;
      margin: 5px;

      img {
        max-width: 28px;
        max-height: 28px;
      }
    }

    &__description {
      text-align: center;
      margin: 10px;
    }

    &__info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-top: 2px solid #F8F6F2;
      padding: 15px 5px;

      i {
        color: @brand-primary;
        margin-right: 2rem;
        font-size: 1.5em;
      }
    }

    &__text {
      text-align: center;
      border-top: 2px solid #F8F6F2;
      padding: 15px 5px;

      &--custom {
        border-top: none;
      }

      &--custom .btn {
        border-radius: 27px;
        padding-left: 25px !important;
        padding-right: 25px !important;
      }

      ol {
        width: 100%;
        text-align: left;
        padding-inline-start: 25px;

        li {
          margin-bottom: 10px;
        }
      }

      .btn {
        font-size: 1.5em;
      }

      img {
        max-width: 100%;
      }
    }

    &__buttons {
      display: grid;
      column-gap: 10px;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;
      margin: 10px -12px -2px -12px;

      button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 42px;
        border-radius: 27px;
        border: 0;
        box-shadow: 0px 1px 2px 0px #00000040;
        background: #F8F6F2;
        &:active {
          background: darken(#F8F6F2, 10%);
        }
      }
    }
  }
}

.lessons-day-streak {
  position: fixed;
  z-index: 59;

  &__days {
    background: white;
    box-shadow: 0px 1px 2px 0px #00000040;
    display: flex;
  }

  &__day {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__name {
      font-weight: bold;
      color: #363636;
      width: 30px;
      height: 30px;
      aspect-ratio: 1;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__medal {
      opacity: 0.25;
      img {
        max-width: 26px;
        max-height: 26px;
      }
    }

    &--today &__name {
      background: #176CAD;
      color: white;
    }

    &--finished &__medal {
      opacity: 1;
    }
  }

  @media (min-width: @screen-md-min) {
    border-left: 1px solid #dfddd9;
    width: @side-navigation-width + 1px;
    margin-left: -1px;
    top: 90px;

    &__days {
      margin-left: 10px;
      padding: 10px;
      border-radius: 15px;
      flex-direction: column;
    }

    &__day {
      flex-direction: row;
      margin: 10px 0;
    }
  }
  @media (max-width: @screen-sm-max) {
    left: 0;
    top: 0;
    width: 100%;
    height: 80px;
    padding-left: 1px;
    padding-right: 1px;

    &__days {
      .container;
      height: 100%;
      border-radius: 0 0 15px 15px;
      flex-direction: row;
      justify-content: space-evenly;
      padding: 8px 0;
    }

    &__day {
      flex-direction: column;
    }
  }
}
