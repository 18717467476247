html, body {
  font-size: @font-size-base;
  margin: 0;
  padding: 0;
  line-height: @line-height-computed;
}

@media (min-width: @screen-xs) {
  html {
    font-size: 13px;
  }
}

@media (min-width: @screen-sm) {
  html {
    font-size: 14px;
  }
}

@media (min-width: @screen-md) {
  html {
    font-size: 15px;
  }
}

@media (min-width: @screen-lg) {
  html {
    font-size: 16px;
  }
}

@media (min-width: @screen-xl) {
  html {
    font-size: 17px;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga" on;
  text-rendering:optimizeLegibility;
  background-color: @jumbotron-bg;
}

#main-container {
  padding-bottom: env(safe-area-inset-bottom);
}

.text-default {
  color: @text-color;
}

.text-small {
  font-size: 0.7em;
}

.space-left {
  margin-left: 1rem;
}
.space-right {
  margin-right: 1rem;
}
.space-top {
  margin-top: 1rem;
  &-large {
    margin-top: 3rem;
  }
}
.space-bottom {
  margin-bottom: 1rem;
  &-large {
    margin-bottom: 3rem;
  }
}

p, label {
  font-size: 1.1rem;
}

.inverse {
  color: #fff;
}

input[type=text], input[type=number], input[type=email], input[type=password], textarea, select {
  background-color: #fff !important;
  color: @gray-dark;
  &::placeholder {
    color: @gray;
  }
}

input[type=radio], input[type=checkbox] {
  box-shadow: none !important;
  &:focus {
    outline: none;
  }
}

.panel {
  border: none;

  &--top-space {
    margin-top: 3rem;
  }

  &--top-inner-space {
    padding-top: 3rem;
  }

  &--bottom-inner-space {
    padding-bottom: 3rem;
  }

  &--inner-space {
    padding: 3rem;

    @media (min-width: @screen-md-min) {
      padding-left: 5rem;
      padding-right: 5rem;
    }
  }

  &--contra-space {
    .panel-body {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.panel-in-list {
  .panel();
  .panel-default();
  display: block;
  text-decoration: none !important;
  overflow: hidden;
  color: @text-color;

  &--clickable {
    cursor: pointer;
    transition: box-shadow 0.3s linear;

    &:hover {
      cursor: pointer;
    }
  }
}

.panel-body {
  display: block;
}

.panel-search {
  &, input, .btn {
    height: 3.5rem;
  }
  overflow: hidden;

  input {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }

  .btn {
    color: @gray-darker;
    padding: 0;
    width: 4rem;
    text-decoration: none;
    padding: 8px 10px!important;

    i {
      display: inline-block;
      border-left: 1px solid @gray-lighter;
      width: 100%;
      text-align: center;
      height: 100%;
      line-height: 2.1rem;
      font-size: 150%;
    }
  }
}

//logo in nav
.logo {
  height: 33px;
  margin-top: -5px;

  &.light {
    display: none;
  }
}

// Math

math {
  font-weight: bold;
}

// Progress bar
.progress {
  height: 0.6rem;
}

// Navbar
.navbar {
  border: none !important;
  // .box-shadow(0 1px 2px rgba(0,0,0,.3));

  .nav > li {
    display: inline-block;
  }

  &-nav {
    margin-top: 0;
    margin-bottom: 0;
  }

  &-right {
    margin-right: 0;
  }

  .container {
    padding: 0px;
  }
}

.student-navigation, .teacher-navigation {
  .nav-auth {
    &__admin-login {
      background: @brand-warning;
    }
  }
}

@media (max-width: @screen-md-min) {
  .navbar {
    &-collapse, &-nav {
      text-align: center;
    }

    .container {
      padding: 0 15px;
    }

    &-nav > li > a {
      padding: 15px !important;
    }

    &-wrapper {
      &--two-rows {
        height: 140px !important;

        .nav-language, .nav-auth {
          margin-top: 5px !important;
        }

        .navbar-header {
          max-width: auto;
        }
      }
    }
  }

  .student-navigation, .teacher-navigation {
    .nav-language { float: left; margin-left: 2%;}
    .nav-auth { float: right; margin-right: 2%; }

    .nav-language, .nav-auth {
      width: 48%;
      display: block;
      margin-top: 5px !important;

      li {
        display: block;
      }

      li > a {
        padding: 10px !important;
        display: block;
      }
    }
  }

  .visitor-navigation {
    .nav-language {
      display: block;
      li, li a {
        display: block;
      }
    }
  }

  .navbar-nav .dropdown-menu > li > a {
    padding: 3px 10px !important;
  }
}

.visible-offset {
  --visible-offset: 90px;
  &--two-rows {
    --visible-offset: 165px;
  }
  &--page {
    --visible-offset: 64px;
  }
  &--page&--two-rows {
    --visible-offset: 128px;
  }
  &--hidden {
    @media (max-width: @screen-sm-max) {
      --visible-offset: 15px;
    }
  }
  @media (max-width: @screen-md-min) {
    &--two-rows {
      --visible-offset: 140px;
    }
  }
}

.navbar-wrapper {
  height: 90px;

  &--two-rows {
    position: relative;
    height: 165px;

    &:before {
      content: " ";
      position: fixed;
      top: 64px;
      z-index: 61;
      width: 100%;
      height: 1px;
      background-color: @jumbotron-bg;
    }

    .navbar {
      margin-bottom: 0 !important;
      min-height: auto;
    }

    .navbar-collapse {
      border: 0;
    }

    .navbar-header {
      width: auto;
      min-width: 70%;
      @media (min-width: @screen-md-min) {
        max-width: 73%;
      }
      @media (min-width: @screen-lg-min) {
        max-width: 78%;
      }
    }

    .navbar-nav > li > a {
      padding: 19.5px 15px;
    }

    .navbar-default .navbar-default{
      .container {
        padding: 0 !important;
      }
      .nav-links {
        li:hover {
          background-color: rgba(0,0,0,0.02);
        }
      }
    }
  }

  &--page {
    height: 64px;
  }
  &--page&--two-rows {
    height: 128px;
  }

  &--hidden {
    @media (max-width: @screen-sm-max) {
      height: 15px;
      * {
        display: none !important;
      }
    }
  }
}

@side-navigation-width: 100px;

.side-navigation-wrapper {
  &__side {
    .side-navigation {
      a {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #363636;
        text-decoration: none;
        background: white;
        font-size: 0.9em;

        i {
          font-size: 250%;
          margin: 0;
          color: #C7C7C7;
        }

        .sale {
          width: 58px;
          height: 21px;
          font-size: 15px;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #FA7652;
          text-align: center;
          color: #FFFFFF;
          font-weight: 700;
          border-radius: 5px;
          box-shadow: 0px 1px 2px 0px #00000040;
        }
      }
    }
  }

  @media (min-width: @screen-md-min) {
    padding-left: @side-navigation-width;

    &__side {
      position: relative;
      margin-left: -@side-navigation-width;

      .side-navigation {
        border-right: 1px solid #dfddd9;
        width: @side-navigation-width + 1px;
        position: fixed;
        top: 90px;

        a {
          width: 100%;
          margin-bottom: 20px;
          border-radius: 15px 0 0 15px;
          box-shadow: 0px 1px 2px 0px #00000040;
          padding: 5px;
          transition: color 0.2s;

          i {
            transition: color 0.2s;
            margin: 10px 0;
          }

          &.active, &:not(.disabled):hover {
            background: @brand-primary;
            &, i {
              color: white;
            }
          }

          .sale {
            top: 0px;
            left: -20px;
            transform: rotate(-36deg);
          }
        }
      }
    }

    &__content {
      border-left: 1px solid #dfddd9;
      padding-left: 15px;

      .container {
        @media (min-width: @screen-sm-min) {
          width: @container-sm - @side-navigation-width - 15px;
        }
        @media (min-width: @screen-md-min) {
          width: @container-md - @side-navigation-width - 15px;
        }
        @media (min-width: @screen-lg-min) {
          width: @container-lg - @side-navigation-width - 15px;
        }
      }
    }

    &--day-streak {
      padding-right: @side-navigation-width;
    }
    &--day-streak &__content {
      border-right: 1px solid #dfddd9;
      padding-right: 15px;
    }

    &__day-streak {
      position: relative;
      margin-left: 100%;
    }
  }
  @media (max-width: @screen-sm-max) {
    margin-bottom: 80px;

    &--day-streak {
      margin-top: 90px;
    }

    &__side {
      .side-navigation {
        position: fixed;
        left: 0;
        bottom: 0;
        height: calc(60px + env(safe-area-inset-bottom));
        z-index: 59;
        width: 100%;
        padding-left: 1px;
        padding-right: 1px;

        &__content {
          .container;
          height: 100%;
          background: white;
          box-shadow: 0px -1px 2px 0px #00000040;
          border-radius: 15px 15px 0 0;
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          padding-bottom: env(safe-area-inset-bottom);

          each(range(1, 10, 1), {
            &--@{value} {
              grid-template-columns: repeat(@value, 1fr);
            }
          })
        }

        a {
          height: 100%;
          margin: 1px 6px;
          i {
            font-size: 200%;
            transition: color 0.2s;
          }
          & > span {
            display: none;
          }
          justify-content: space-between;

          &::before {
            content: '';
            width: 100%;
            border: 2px solid white;
            border-radius: 2px;
            transition: border-color 0.2s;
          }

          &::after {
            content: '';
          }

          &.active, &:not(.disabled):hover {
            i {
              color: @brand-primary;
            }
            &::before {
              border-color: @brand-primary;
            }
          }

          .sale {
            width: 48px;
            height: 16px;
            font-size: 12px;
            top: 1px;
          }
        }
      }
    }
  }
}

.navbar {
  z-index: 60;

  &--aux {
    z-index: auto;
  }
}

@media (max-width: @screen-md-min) {
  .nav-language, .nav-auth {
    margin-top: 5px !important;
  }
}

/*XXX ID is used because we have to override fairly specific !important rule. */
.class-selector {
  float: left;
  margin-bottom: 0px;
  overflow-x: auto;
  max-width: 100%;
  white-space: nowrap;

  @media (max-width: @screen-md-min) {
    margin-left: 10px;
    min-height: auto;
    max-width: 80%;
  }

  li {
    display: inline-block;
    float: none !important;
  }

  &-collapsed {
    margin-top: 5px;
    margin-bottom: 0px;
    display: block;

    li {
      display: inline-block;
    }
  }
}

.class-selector-item {
  position: relative;

  button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: auto 0;
    margin: 10px 6px;
    padding: 8px 14px;
    border-radius: 4px;
    border: 0 none;
    background-color: transparent;
    background-color: rgba(0,0,0,0.06);
  }

  &.active button,
  button:hover,
  button:focus {
    outline: none;
  }

  &:hover button {
    background-color: rgba(0,0,0,0.14);
  }

  &.active button {
    background-color: @brand-success!important;
    color:white!important;
  }

  &__badge {
    @badge-size: 1.2; //rem

    display: block;
    position: absolute;
    right: 1px;
    top: 10.5px;
    width: @badge-size * 1rem;
    height: @badge-size * 1rem;
    border-radius: @badge-size * 1rem;
    line-height: @badge-size * 1rem;
    text-align: center;
    font-size: @badge-size * 0.8em;
    font-weight: bold;
    color: #fff;
    background: @brand-bad;
  }
}

.class-bordered-item {
  padding: 2px 8px;

  @media (min-width: @screen-md-min) {
    padding: 12px 15px;

    &.left-margin-minus-15 {
      margin-left: -15px;
    }
  }

  .form-group {
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 0 !important;
  }
}

.menuitem {
  &__badge {
    @badge-size: 1.2; //rem

    display: block;
    position: absolute;
    right: -1px;
    top: 8px;
    width: @badge-size * 1rem;
    height: @badge-size * 1rem;
    border-radius: @badge-size * 1rem;
    line-height: @badge-size * 1rem;
    text-align: center;
    font-size: @badge-size * 0.8em;
    font-weight: bold;
    color: #fff;
    background: @brand-bad;
  }
}

.feedback {
  &__button {
    position: fixed;
    right: 12px;
    bottom: 20px;
    z-index: 80;
    background: #f0f0f0;
    display: block;
    height: 30px;

    @media (max-width: @screen-sm-max) {
      position: static;
      float: right;
      margin: 20px 10px 25px 0;
      .clearfix;
    }

    i {
      margin-top: -13px;
    }
  }

  &__nav-item {
    padding: 0 !important;
    height: 25px;
    width: 55px;

    @media (min-width: @screen-md-min) {
      margin-left: -15px;
    }

    &.main {
      margin-top: 11px;
      margin-left: 0 !important;
    }

    & &__button {
      color: @link-color !important;
      font-size: 3em;
      padding: 0 !important;

      &:hover {
        color: @link-hover-color !important;
      }

      i {
        position: absolute !important;
        top: -1px;
        left: 15px;
      }
    }

    &.reverse &__button i {
      left: 5px;
    }
  }

  // &__left {
  //   .feedback__button {
  //     @media (max-width: @screen-xs-max) {
  //       position: absolute;
  //       left: 15px;
  //       top: 105px;
  //       width: 56px;
  //     }
  //   }
  // }

  textarea {
    height: 200px;
  }
}

.school-panel {
  // .box-shadow(0 -1px 2px rgba(0,0,0,.3));
  // background: #fff;
  height: 0;

  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 40;

  &__grid {
    position: relative;
  }
}

.bottom-gap {
  width: 100%;
  height: 60px;

  @media (max-width: @screen-sm-max) {
    height: 80px;
  }
}

.table {
  tr.thead > th {
    vertical-align: bottom;
    border-bottom: 2px solid @table-border-color;
    border-top: 0px;
  }
}

.table-cell-border-left {
  border-left: 1px solid @table-border-color;
}
.table-cell-border-right {
  border-right: 1px solid @table-border-color;
}
.table-row-no-border {
  th, td {
    border-top: 0 !important;
  }
}


@trophy-color: #f5df15;
.trophy {
  color: @trophy-color;
}

@media (max-width: 768px) {
  .container {
      width: auto !important;
  }
}

.menu-flag {
  height: 12px;
  margin-right: 8px;
}

.flag-menu ul {
  background-color: rgb(246,246,250);
}

// Font Aswesome
.fa-nested {
  position: relative;

  .fa-inverse {
    color: @jumbotron-bg;
    position: absolute;
    left: 22%;
    top: 22%;
    font-size: 55%;
    @media (max-width: @screen-xs-max) {
      top: 21%;
    }
    &.fa-small {
      top: 24%;
      font-size: 50%;
    }
    &.fa-fw {
      left: 50%;
      margin-left: -1.28571em / 2;
    }
  }

  .fa-flag-checkered {
    left: 16%;
  }
}

.fa-custom-stack {
  width: 5rem;
  height: 4rem;
  line-height: 4rem;
  font-size: inherit;

  .fa-inverse {
    color: @jumbotron-bg;
  }

  .fa-sign-in {
    font-size: 64%;
    margin-left: 0.5%;

    @media (max-width: @screen-sm-max) {
      font-size: 40%;
    }
  }
}
