@xcontrol-bg-base: #EFEDE9;
@xcontrol-fg-base: #363636;
@xcontrol-bg-active: @brand-primary;
@xcontrol-fg-active: #ffffff;
@xcontrol-bg-hover: darken(@brand-primary, 10%);
@xcontrol-fg-hover: #ffffff;

.xcontrol {
  transition: color 0.2s, background-color 0.2s;
}
.xshadow {
  box-shadow: 0px 1px 2px 0px #00000040;
  &--huge {
    box-shadow: 0px 2px 4px 0px #00000040;
  }
}

.xselect {
  .xcontrol;
  .xshadow;
  width: 100%;
  padding: 10px;
  border-right: 10px solid @xcontrol-bg-base;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  appearance: auto;

  &, option {
    background: @xcontrol-bg-base !important;
    font-weight: bold;
  }
}

.xbutton {
  .xcontrol;
  text-decoration: none !important;
  display: inline-flex;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  background: @xcontrol-bg-base;
  color: @xcontrol-fg-base;

  flex-direction: row;
  align-items: center;
  justify-content: center;

  &.disabled {
    cursor: not-allowed;
  }

  &.active {
    background: @xcontrol-bg-active;
    color: @xcontrol-fg-active;
  }

  &:not(.disabled):hover {
    background: @xcontrol-bg-hover;
    color: @xcontrol-fg-hover;
  }
}
