@lesson-task-base: #7a7976;
@lesson-task-selected: #0070ba;
@lesson-task-correct: @brand-success;
@lesson-task-incorrect: @brand-danger;

@lesson-nav-default: #212528;
@lesson-nav-step: #37a8e5;
@lesson-nav-hint: #0070ba;
@lesson-nav-action: #0AA06E;

@lesson-hint-note: #f8f6f2;

.lesson-content {
  position: relative;

  &__controls-wrapper {
    position: sticky;
    top: calc(var(--visible-offset, 0px) - var(--custom-offset, 0px));
    z-index: 55;
    margin-bottom: 1rem;
    transition: all 0.1s ease;
  }

  &__controls, &__other-controls {
    display: grid;
    grid-template-rows: auto;
    column-gap: 0.5rem;
    transition: all 0.1s ease;

    margin-bottom: 1rem;
    height: 6.5em;
    @media (max-width: @screen-xs-max) {
      height: 4.5rem;
    }
  }

  &__controls {
    grid-template-columns: 100px 100px auto 100px 100px;

    @media (max-width: @screen-xs-max) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      &__extra {
        display: none;
      }
    }
  }

  &__other-controls {
    position: relative;

    &__extra {
      display: grid;
      column-gap: 0.5rem;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;
      @media (min-width: @screen-lg-min) {
        padding-left: 220px;
      }
      @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
        padding-left: 220px;
      }
    }

    @media (min-width: @screen-md-min) {
      margin-left: calc((100px + 0.5rem) * 2);
      margin-right: calc((100px + 0.5rem) * 2);
      margin-top: calc((6.5em + 1rem) * (-1));
    }

    @media (min-width: @screen-sm-min) {
      grid-template-columns: 100px auto;
    }
    @media (max-width: @screen-xs-max) {
      grid-template-columns: 1fr 1fr 1fr 1fr;

      &__extra {
        grid-column-start: 2;
        grid-column-end: 5;
      }
    }
  }

  &__control, &__other-control {
    width: 100%;
    height: 100%;

    .btn {
      border-radius: 15px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.0625);
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      i {
        display: block;
        height: auto;
        font-size: 3rem;
        line-height: 1;
        color: @lesson-nav-default;
        margin-right: 0;
      }

      span {
        display: inline-block;
        overflow: hidden;
        max-height: 50px;
        transition: all 0.1s ease-in;
      }

      &.disabled, &:disabled {
        background: #eeeeee;
        opacity: 1;
        filter: grayscale(75%);
      }
    }

    &--step .btn i {
      color: @lesson-nav-step;
    }
    &--hint .btn i {
      color: @lesson-nav-hint;
    }
    &--action .btn i {
      color: @lesson-nav-action;
    }
    &--action .btn.btn-success i {
      color: white;
    }
    &--warning .btn i {
      color: @brand-warning;
    }
  }

  &__controls-wrapper--scroll &__controls,
  &__controls-wrapper--scroll &__other-controls {
    height: 4em; // 2.5em less
    @media (max-width: @screen-xs-max) {
      height: 3rem; // 1.5rem less
    }

    .btn {
      span {
        max-height: 0;
        opacity: 0;
      }
    }
  }
  &__controls-wrapper--scroll {
    margin-bottom: calc(2.5em + 1rem);
    @media (max-width: @screen-xs-max) {
      margin-bottom: calc(1.5rem + 1rem);
    }
  }
  &__controls-wrapper--scroll &__other-controls {
    @media (max-width: @screen-xs-max) {
      margin-top: -0.5rem;
      margin-bottom: calc(1.5rem * 2 + 1.5rem);
    }
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
      margin-top: -0.5rem;
      margin-bottom: calc(2.5em * 2 + 1.5rem);
    }
    @media (min-width: @screen-md-min) {
      margin-top: calc((4em + 1rem) * (-1));
    }
  }

  @media (min-width: @screen-sm-min) {
    &__other-control {
      .btn {
        flex-direction: row;
        justify-content: space-around;

        i {
          padding-right: 0.25rem;
        }
      }
    }
  }

  @media (max-width: @screen-xs-max) {
    &__control, &__other-control {
      .btn {
        padding: 2px 3px !important;
        font-size: 0.9em;

        i {
          font-size: 2.5rem;
        }
      }
    }
  }

  &__block {
    background-color: white;
    border: 0 solid white;
    border-left-width: 8px;
    border-right-width: 8px;
    display: block;
    border-radius: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.0625);
    margin-bottom: 1rem;
    padding: 1rem 1.5rem;
    padding-top: 2rem;
    @media (max-width: @screen-sm-max) {
      padding: 0.5rem 1rem;
      padding-top: 1.5rem;
    }

    &--green {
      border-left-color: @brand-success;
    }
    &--red {
      border-left-color: @brand-danger;
    }
    &--blue {
      border-left-color: @brand-primary;
    }

    &--tight {
      padding-bottom: 0;
    }

    &--centered {
      text-align: center;
    }

    &__title {
      border-bottom: 2px solid @lesson-nav-hint;
      text-align: center;
      font-size: 1.5em;
      line-height: 1.5em;
      margin-top: -0.5rem;
      margin-bottom: 1rem;
      padding-bottom: 0.5rem;
      font-weight: bold;

      &--correct {
        border-bottom-color: @brand-success;
      }
      &--incorrect {
        border-bottom-color: @brand-danger;
      }
    }

    // TODO: refactor
    .lesson__tags {
      margin: 3rem 0;
      .tag {
        &__header {
          height: 2rem;
          line-height: 2rem;
        }
      }
    }

    .katex {
      background-color: #f1f1f1;
      color: #5c5c5c;
      white-space: nowrap;
      padding: 4px;
      line-height: 180%;
      display: inline-block;
      border-radius: 10px;
      .base {
        line-height: 100%;
      }
    }

    // TODO: refactor
    .visualization__box {
      margin-bottom: 1rem;
      &:not(&--fullscreen) {
        @media (max-width: @screen-xs-max) {
          margin-left: -1rem;
          margin-right: -1rem;
        }
      }

      iframe {
        display: block;
      }

      @media (max-width: @screen-xs-max) {
        &::after {
          display: flex;
          content: '⬍';
          font-size: 3rem;
          justify-content: center;
          align-items: center;
          width: 38px;
          height: 70px;
          position: absolute;
          top: 50%;
          margin-top: -35px;
          right: -23px;
          z-index: 50;
          background: #F8F6F2;
          box-shadow: 0px 1px 2px 0px #00000040;
          border-radius: 15px 0px 0px 15px;
        }
      }
    }

    // TODO: refactor
    .embed {
      text-align: center;
      iframe {
        width: 100%;
        max-height: 90vh;
        aspect-ratio: ~'16/9';
        border: 0;
      }
    }
  }

  &__title {
    height: 3rem;
    font-size: 2.5em;
  }

  &__hint, &__hint-background {
    cursor: default;

    width: 100%;
    top: 0;
    left: 0;

    visibility: hidden;
    opacity: 0;
    transition: opacity 0.1s;

    &--show {
      visibility: visible;
      opacity: 1;
    }
  }

  &__hint-background {
    position: fixed;
    z-index: 100;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
  }

  &__hint {
    position: absolute;
    z-index: 101;

    .navbar-wrapper {
      visibility: hidden;
    }

    &__note {
      position: relative;
      text-align: center;
      background: @lesson-hint-note;
      margin-top: 1rem;
      margin-left: calc(-1.5rem - 8px);
      margin-right: calc(-1.5rem - 8px);
      padding: 1rem 1.5rem 0.25rem 1.5rem;
      border-radius: 0 0 15px 15px;
      @media (max-width: @screen-sm-max) {
        margin-top: 0.5rem;
        margin-left: calc(-1rem - 8px);
        margin-right: calc(-1rem - 8px);
        padding: 1rem 1rem 0.25rem 1rem;
      }

      p {
        font-size: 1em;

        a {
          color: black;
          text-decoration: underline;
          font-weight: bold;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    &__skip {
      position: absolute;
      bottom: 5px;
      left: 50%;
      width: 100%;
      max-width: 350px;
      margin-left: -175px;
      @media (max-width: 380px) {
        margin-left: 0;
        left: 0;
      }

      border: 1px solid black;
      background: white;
      padding: 1rem;
      border-radius: 15px;

      display: none;
      &--show {
        display: block;
      }

      .btn {
        width: 45%;
        border: 1px solid black;
        border-radius: 15px;

        &.pull-right {
          background: black;
          color: white;
        }
      }
    }
  }

  &__image-container {
    margin-top: 12px;
    text-align: center;
  }

  &__image {
    max-width: 100%;
  }

  &__closed-question {
    &__option {
      border: 2px solid @lesson-task-base;
      border-radius: 5px;
      cursor: pointer;
      padding: 1rem;
      display: flex;
      flex-flow: row;
      align-items: center;
      margin-bottom: 1rem;

      & > p {
        margin: 0;
      }

      &::before {
        content: '';
        display: block;
        flex: 0 0 auto;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background: white;
        border: 2px solid white;
        box-shadow: 0 0 0 2px @lesson-task-base;
        margin-right: 1rem;
      }

      &--selected, &:hover  {
        border-color: @lesson-task-selected;
        &::before {
          box-shadow: 0 0 0 2px @lesson-task-selected;
        }
      }
      &--selected {
        &::before {
          background: @lesson-task-selected;
        }
      }

      &--correct {
        border-color: @lesson-task-correct !important;
      }
      &--selected&--correct::before {
        background: @lesson-task-correct;
        box-shadow: 0 0 0 2px @lesson-task-correct;
      }

      &--incorrect {
        border-color: @lesson-task-incorrect !important;
      }
      &--selected&--incorrect::before {
        background: @lesson-task-incorrect;
        box-shadow: 0 0 0 2px @lesson-task-incorrect;
      }

      &--disabled {
        cursor: not-allowed;
      }
    }

    &--images {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;

      .lesson-content__image-container {
        margin: 10px;
      }
    }

    &--images &__option {
      width: 45%;
      margin: 1rem;
      display: flex;
      justify-content: center;
      align-content: center;
      border-width: 5px;

      @media (max-width: @screen-xs-max) {
        width: 100%;
      }

      &::before {
        display: none;
      }
    }
  }

  &__number-input {
    input[type=text] {
      font-size: 1.8em;
      height: 3rem;

      &, &:disabled {
        box-shadow: 0 0 0 2px @lesson-task-base;
      }
      &:focus {
        box-shadow: 0 0 0 2px @lesson-task-selected;
      }

      @media (min-width: @screen-sm-min) {
        max-width: 400px;
      }
    }
  }

  &__text-input {
    input[type=text], textarea {
      width: 100%;

      &, &:disabled {
        box-shadow: 0 0 0 2px @lesson-task-base;
      }
      &:focus {
        box-shadow: 0 0 0 2px @lesson-task-selected;
      }
    }

    textarea {
      resize: vertical;
      min-height: 11em;
      padding: 0.5rem 1rem;
    }
  }

  &__page {
    position: relative;
    text-align: center;
    margin-bottom: 1rem;

    &__info, &__scroll {
      position: absolute;
      top: 0;
      cursor: pointer;
      font-size: 1.5em;
      color: @lesson-nav-default;
      opacity: 0.25;
    }
    &__info {
      left: 0;
    }
    &__scroll {
      right: 0;
    }
  }
}
