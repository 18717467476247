.auth {
  text-align: center;
  margin: 0 auto;
  max-width: 35rem;

  &--registration {
    max-width: 60rem;
  }

  &__country {
    margin: 3rem auto;

    .form-group {
      max-width: 22rem;
      margin: 0 auto;
    }
  }

  .padded-link {
    margin-bottom: 32px;
    display: block;
  }

  &__login{
    &__social{
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      grid-gap: 2vh;
      padding-bottom: 4vh;

      .btn{
        width: 100%;
        text-align: left;

        i, img {
          float: left;
        }
        i {
          padding-top: 5px;
          padding-right: 12px;
        }
      }
    }
  }

  &__school {
    margin: 2rem auto;
  }

  &__subtitle {
    margin-bottom: 3rem;
  }

  &__separator {
    margin-bottom: 1rem;
  }

  &__facebook {
    background-color: @color-facebook;
    color: #fff;

    &:hover {
      color: #fff;
      background-color: darken(@color-facebook, 10%);
    }
  }

  &__option {
    margin-bottom: 1rem;
  }

  &__google {
    background-color: @color-google;
    color: #fff;

    &:hover {
      color: #fff;
      background-color: darken(@color-google, 10%);
    }
  }

  &__microsoft {
    background-color: @color-microsoft;
    color: #fff;

    &:hover {
      color: #fff;
      background-color: darken(@color-microsoft, 10%);
    }
  }

  &__panel {
    padding-top: 2rem;

    form {
      text-align: left;
    }

    &--thin {
      padding: 1.5rem 1rem 1rem 1rem;
    }

    &__space {
      height: 1.5rem;
    }
  }

  &__split-panel {
    .panel-body {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  &__split {
    h3 {
      text-transform: uppercase;
      font-size: 1.5em;
      margin-bottom: 2rem;
    }

    &__social {
      .btn {
        width: 100%;
        text-align: left;

        i {
          padding-right: 12px;
        }
      }
    }

    &__separator {
      p {
        font-size: 1.5em;
        font-weight: bold;
        margin-top: 25px;
        margin-bottom: 1.8rem;
      }

      div {
        width: 0;
        height: 23rem;
        border-left: 1px solid black;
        margin: 0 auto;

        @media (max-width: @screen-sm-max) {
          display: none;
        }
      }
    }

    &__email {
      .panel-heading {
        text-align: left;
        font-size: 1.5em;

        i {
          padding-right: 0.7rem;
        }
      }

      .panel {
        background-color: rgba(0,0,0,0.04);
      }
    }
  }

  &__block-label {
    display: block !important;
  }
  &__radio {
    padding-left: 1.5rem;
    padding-right: 3.5rem;
    &-label {
      padding-left: 1rem;
    }
  }
  &__info {
    font-size: 0.8rem;
  }

  &__delete-acc {
    text-align: left;
    margin-top: 32px;

    a {
      color: #666;
    }
  }
}

.registration {
  a {
    font-size: 2em;
    margin: 2rem;
  }

  &-student {
    &__text {
      padding-top: 1.5rem;
    }
  }
}

.onboarding {
  &__filter {
    h2 {
      text-align: center;
    }

    &__next {
      float: right !important;
      width: 5rem;
      margin-top: -5rem;
      margin-bottom: 3rem;
      text-align: center;
    }

    &__item {
      cursor: pointer;
      border: 2px solid transparent;
      margin-bottom: 2rem;
      height: 35rem;

      &:hover, &--active {
        border-color: @brand-success;
      }

      &__label, &__content {
        @media (min-width: @screen-lg) {
          font-size: 1.5em;
        }
      }

      &__label {
        height: 2em;
        line-height: 2em;
        text-align: center;
      }

      &:hover &__label, &--active &__label {
        background: @brand-success;
        color: white;
      }

      &__icon {
        text-align: center;
        font-size: 8em;
        margin: 2.5rem 0;
      }

      &:hover &__icon, &--active &__icon {
        color: #f5cf15;
      }

      &__title {
        font-size: 2em;
        text-align: center;
        margin: 1.5rem 0;
      }

      &__content {
        padding: 0.2em 0.7em 0.2em 0;

        &__time, li {
          margin: 1rem 0;
        }

        ul {
          padding-left: 3rem;
        }

        li {
          padding-left: 0.5rem;
        }

        &__time {

          * {
            vertical-align: middle;
          }

          &__icon {
            float: left;
            line-height: 2em;
          }

          &__value {
            display: block;
            overflow: hidden;
            padding-top: 0.3em;
          }

          i {
            width: 3.5rem;
            padding-left: 1.2rem;
            font-size: 1.5em;
          }
        }
      }
    }
  }
}

.invite {
  h2 {
    text-align: center;
    margin-bottom: 3rem;
  }

  .panel {
    max-width: 50rem;
    margin: 0 auto;
    padding: 1rem;
  }
}

.cookie-notice {
  bottom: -300px;
  left: 12px;
  position: fixed;
  z-index: 2;
  padding: 12px;
  color: #444;
  background-color: white;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2),0 4px 20px 0 rgba(0,0,0,0.19);
  transition: bottom 1s;
  width: 80%;
  max-width: 720px;

  p {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 8px;
    text-align: block;
  }

  &.visible{
    bottom: 16px;
  }

  &__options {
    display: flex;
    flex-direction: row;
    align-content: center;

    div {
      min-width: 124px;
    }
  }

  &__accept {
    font-weight: bold;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__more:hover {
    color: @blue !important;
  }

  &__accept, &__more {
    padding: 0 6px;
  }
}
