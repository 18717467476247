.xpicker {
  display: inline-block;
  position: relative;
  user-select: none;

  &__value {
    font-weight: bold;
    font-size: 1.1em;
    margin: 0 2px;
  }

  &__increment, &__decrement {
    position: absolute;
    width: 20px;
    left: 50%;
    margin-left: -10px;
    text-align: center;
    cursor: pointer;
    color: #555;

    &--disabled {
      cursor: not-allowed;
      color: #aaa;
    }
  }

  &__increment {
    bottom: 80%;
  }

  &__decrement {
    top: 80%;
  }
}
