.btn {
  display: inline-block;
  border-radius: 3px;
  box-shadow: none;

  &.btn-xs {
    padding: 1px 5px !important;
  }

  &.full {
    color: white!important;
    background-color: @blue-base;
    &:hover,
    &:focus,
    &.active {
      background-color: darken(@blue-base, 10%)!important;
    }
  }

  &.bordered {
    border: 2px solid @blue-base;
    color: @blue-base!important;
    background-color: transparent;
    padding: 4px 9px !important;

    &:hover,
    &:focus,
    &.active {
      color: #fff !important;
      background-color: @blue-base!important;
    }
  }

  padding: 5px 10px!important;

  i {
    margin-right: 8px;
    height: 100%;
  }
  i:last-child {
    margin-right: 0px !important;
  }

  &.inline-btn {
    margin-left: 28px;
    margin-top: -12px;
  }

  &-tooltip-wrapper {
    display: inline-block;
    cursor: not-allowed;

    .btn:disabled {
      pointer-events: none;
    }
  }
}
