.visualization-panel-wrapper {
  min-height: 17rem;
}

.visualization-panel-delimiter {
  margin: 1rem 1rem 2rem 1rem;
  border-bottom: 1px solid #fff;
}

.visualization-panel-subtitle {
  padding: 0 15px 15px 15px;
  margin: 0;
  color: #fff;
}

.visualization {
  &__btn {
    font-size: 200%;
    float: left;
    margin-right: 1rem;

    &:hover {
      color: @gray-dark !important;
    }
    color: @gray !important;

    &--right {
      float: right;
      margin-right: 0;
      margin-left: 1rem;
    }
  }

  &__custom-btn {
    float: left;
    margin-top: -0.3rem;
    margin-left: -0.3rem;
  }

  &__type {
    .text-center;
    display: block;
    color: @gray;
    margin-top: 0.5rem
  }

  &__icon {
    font-size: 200%;
    float: right;
    margin-left: 1rem;
    color: lighten(@gray, 20%) !important;
  }

  &__box {
    position: relative;
    text-align: center;
    padding-top: 2.3em;

    &--no-bar {
      @media (max-width: @screen-sm-max) {
        padding-top: 0;
      }
    }

    &--fullscreen {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1000000;
      background: #fff;
    }

    &__favourite {
      position: absolute;
      left: 3px;
      top: 3px;
      font-size: 1.5em;
    }

    &__fullscreen {
      position: absolute;
      right: 3px;
      top: 3px;
      font-size: 1.5em;
      text-decoration: none !important;

      span {
        font-size: 0.7em;
        vertical-align: middle;
      }

      @media (max-width: @screen-sm-max) {
        display: none;
      }
    }

    iframe, img {
      border-width: 0;
      border-style: none;
      width: 1px;
      min-width: 100%;
      max-width: 100%;

      // @media (max-width: @screen-sm-max) and (min-aspect-ratio: ~'1/2') {
      //   &.portrait {
      //     min-width: 37vh;
      //     max-width: 37vh;
      //   }
      // }

      // @media (max-width: @screen-sm-max) and (min-aspect-ratio: ~'2/1') {
      //   min-width: 90%;
      //   max-width: 90%;
      // }
    }

    @media (min-aspect-ratio: ~'1/1') {
      iframe.portrait, img.portrait {
        min-width: 50%;
        max-width: 50%;
      }
    }

    &--fullscreen img, &--fullscreen iframe {
      width: 1px;
      min-width: 100%;
      max-width: 100%;

      &.portrait {
        width: 100%;
        min-width: 0;
        max-width: 48vh;
      }

      @media (min-aspect-ratio: ~'1/1') {
        width: 186vh;
        min-width: 0;
        max-width: 100%;

        &.portrait {
          width: 1px;
          min-width: 46vh;
          max-width: 46vh;
        }
      }
    }

    img {
      cursor: not-allowed;
    }
  }
}

.visualizations-catalog {
  &__note {
    line-height: 1.4rem;
    margin-bottom: 1rem;
  }
}
