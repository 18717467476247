.xchat {
  position: fixed;
  left: 10px;
  bottom: 7px;
  z-index: 120;
  width: 0;

  &__window {
    overflow: hidden;

    &, &__frame-wrapper {
      max-width: 98vw;
      max-height: 85vh;
    }
    width: 0;
    height: 0;
    &--open, &__frame-wrapper {
      width: 450px;
      height: 550px;
    }
    &--open {
      box-shadow: none !important;
      border: 1px solid #D1D1D1;
      border-radius: 7px;
    }
    &__frame-wrapper {
      display: flex;
      flex-direction: column;
      background: white;
    }

    transition-property: width, height;
    transition-duration: 100ms;
    transition-timing-function: cubic-bezier(0.175, 0.665, 0.320, 1);

    &__top {
      flex-shrink: 0;
      height: 50px;
      border-bottom: 1px solid #D1D1D1;
      background: #F8F8F8;
      padding: 0px 8px;
      display: flex;
      align-items: center;
      justify-content: right;

      a {
        margin-left: 10px;
      }
    }

    &__body {
      flex-grow: 999;
      overflow-x: hidden;
      overflow-y: auto;
      display: flex;
      flex-direction: column-reverse;
    }

    &__bottom {
      flex-shrink: 0;
      border-top: 1px solid #D1D1D1;
      background: rgb(238,238,238);
      height: 50px;
      padding: 0px 5px 2px 5px;
      display: flex;
      align-items: center;

      .form-group {
        width: 100%;
        margin: 0;
      }
    }

    &__messages {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__message {
      margin: 2px 5px;
      padding: 5px 8px 8px 8px;
      border-radius: 5px;
      background: @gray-lighter;
      word-break: break-all;
      &--me {
        background: @brand-primary;
        color: white;
        align-self: flex-end;
      }

      &__name {
        font-weight: bold;
      }

      &__time {
        text-align: right;
        font-size: 0.8em;
        line-height: 0.8em;
        opacity: 0.8;
      }
    }
  }

  &__icon {
    .text-primary;
    font-size: 3rem;
    margin-top: 3px;
    position: relative;
    display: inline-block;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }

    &--notification::after {
      content: '';
      position: absolute;
      top: -3px;
      right: -5px;
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      background-color: @brand-warning;
    }
  }

  @media (max-width: @screen-xs-max) {
    left: 3px;
    bottom: 0px;

    &__window {
      &, &__frame-wrapper {
        max-width: 100vw;
      }
      &--open, &__frame-wrapper {
        width: 100vw;
        height: 70vh;
        @media (min-aspect-ratio: ~'1/1') {
          height: 90vh;
        }
      }

      margin-left: -3px;
    }

    &__icon {
      font-size: 2.5rem;
      margin-top: 1px;

      &--notification::after {
        width: 14px;
        height: 14px;
        top: -4px;
      }
    }
  }
}
