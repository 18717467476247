.highlight {
  display: inline-block;
  width: 14.25rem;
  margin: 0 auto;
  padding: 1.5rem 1rem;
  vertical-align: top;

  @media (min-width: @screen-sm-min) {
    width: 16.25rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  &__icon, &__value {
    line-height: 1em;
  }

  &__icon {
    .pull-left();
    font-size: 4rem;
    color: @blue-base;
    margin-right: 1rem;
    margin-bottom: 1.5rem;
  }

  &__value {
    font-size: 2.8rem;
    line-height: 2.8rem;
    margin-top: 0.3rem;
    color: #333333;

    &--small {
      font-size: 1rem;
    }
  }

  &__label {
    color: @gray-light;
    font-size: 1.2rem;
  }

  & &-dataset {
    margin-left: 2rem;
    margin-top: -1rem;
  }
}
