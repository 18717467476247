.xmodal {
  &__close {
    .btn-link();
    font-size: 3em;
    position: absolute;
    top: 15px;
    right: 40px;
    z-index: 110;

    @media (max-width: @screen-xs-max) {
      top: 5px;
      right: 0px;
    }
  }

  &__frame {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    max-height: 99%;
    z-index: 100;

    .box-content();
    min-height: 300px;
    max-height: 99%;
    overflow: auto;
    //display: none;
    visibility: hidden;

    transform: translate(0, 200%);
    transform: translate3d(0, 200%, 0);

    transition-property: transform;
    transition-duration: 700ms;
    transition-timing-function: cubic-bezier(0.175, 0.665, 0.320, 1);

    @media (max-width: @screen-xs-max) {
      padding: 0;
    }

    iframe {
      display: none;
      margin: auto;
    }

    &--open {
      //display: block;
      visibility: visible;

      transform: translate(0, 0);
      transform: translate3d(0, 0, 0);

      iframe {
        display: block;
      }
    }

    &--half-height {
      min-height: 50% !important;
    }

    &--full-height {
      height: 99% !important;
    }

    &--small-iframes {
      iframe {
        max-height: 25rem;
      }
    }
  }

  &__content {
    margin: 0 5rem;
    padding: 0 2rem;
    overflow-x: auto;
    overflow-y: hidden;

    @media (max-width: @screen-xs-max) {
      margin: 1rem 0.5rem 0 0.5rem;
      padding: 0 0.5rem;

      h4 {
        padding-right: 2.5rem;
      }
    }

    &.gray {
      border-left: 5px solid @gray;

      h4 {
        color: @gray !important;
      }
    }
    &.text-success {
      border-left: 5px solid @brand-success;
    }
    &.text-danger {
      border-left: 5px solid @brand-danger;
    }

    &--no-overflow {
      overflow: unset;
    }
  }
}
