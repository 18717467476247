thead {
  counter-reset: steps;
}

.highlight-dataset {
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  font-size: 1.3rem;
  width: 0;
  overflow: auto;
  z-index: 2;
  max-height: 30rem;

  transition-property: width, opacity;
  transition-duration: 300ms;
  transition-timing-function: linear;

  &--open {
    width: 18rem;
    opacity: 1;

    &.highlight-dataset--steps {
      width: 22rem;
    }
  }

  &__items {
    width: 100%;
    overflow: hidden;
  }

  &__item {
    width: 17.5rem;
    padding: 5px;
    position: relative;
    background: #ccc;

    &--inactive > * {
      opacity: 0.5;
    }

    &--selectable {
      cursor: pointer;
    }

    &--selected {
      background: #e5e5e5;
    }
  }

  &--steps {
    .highlight-dataset__item {
      width: 21.5rem;
      padding-right: 4rem;

      &__steps {
        width: 3.6rem;
        position: absolute;
        right: 0.4rem;
        top: 0.3rem;
        font-size: 0.6em;
        text-align: right;
      }
    }
  }
}

.stats {

  .negotiation__group .exam{
      border-style: double;
      border-width: 5px;

  }

  &__link {
    font-size: 14px;
    i {
      margin-right: 6px
    }
  }

  h4 {
    margin-bottom: 2rem;
  }

  h5 {
    font-size: 1.2em;

    padding-left: 0.1rem;
    &.stats-vh {
      padding: 0 3rem;
      color: white;
      margin-bottom: 2rem;
    }

    .stats-vspace {
      margin-left: -2rem;
      text-align: center;
    }
    .stats-vbar {
      margin-left: -0.5rem;

      @media (max-width: @screen-xs-max) {
        margin-left: -1rem;
      }
    }
  }

  &__h6 {
    margin-top: 6rem;
    margin-bottom: -2.5rem;
  }

  .lesson__creation {
    margin: 0 1rem 0 0 !important;
    width: 7rem;

    a {
      margin: 0 !important;
    }
  }

  &__deletion {
    display: inline-block;
    color: @gray-light;
    margin-left: 16px;
    font-size: 1em;

    &:hover {
      color: darken(@gray-light, 20%);
    }
  }

  &__date {
    margin-left: 16px;
    font-size: 0.6em;
  }

  .lesson__next, .tags {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .stats-vbar {
    border-left: 1px solid @gray-light;
    padding-left: 2rem;
  }

  &__suggested-visualization {
    margin-top: 2rem;
    margin-bottom: -25px;

    .panel {
      margin: 0 !important;
    }
  }

  &__in-task-box {
    @media (min-width: @screen-md-min) {
      margin-right: 6rem;
    }

    .text-left;

    &__title {
      margin-top: 3rem;
      margin-bottom: 2rem;
    }
  }

  &__panel {
    margin: 0 0 4rem 0;

    &--with-padding {
      padding: 2rem 1rem;
    }

    &--smaller-margin {
      margin: 2rem 0;
    }

    &__detail {
      margin: 2rem 0;
    }

    /*.highlight:first-child .doughnut {
      // TODO: 6artisans
      // border-right: 1px solid @gray;
    }*/

    .tags {
      margin-bottom: 0;
    }
  }

  &__snapshot {
    img {
      width: 100%;
      max-width: 500px;
      max-height: 500px;
      object-fit: contain;
      object-position: left top;
    }
  }

  a {
    text-decoration: none !important;
  }
  &__class {
    position: relative;
    top: -0.2rem;
  }

  &__correct {
    color: @brand-success;
//    font-weight: bold;
  }

  &__wrong {
//    font-weight: bold;
    color: @brand-danger;
  }

  &__warning {
//    font-weight: bold;
    color: @brand-warning;
  }

  &__question {
    font-size: 1rem;
  }

  &__failed-answer {
    margin-top: 1rem;
//    font-size: 1.05rem;
    font-weight: bold;
  }

  &__tip {
    margin-top: 2rem;
    font-size: 1rem;
  }

  &__success-rate {
    color: #333;
    position: relative;

    &__label, &__value {
      position: relative;
      display: inline-block;
    }

    &__value {
      margin-left: -4.5rem;

      .highlight {
        padding-bottom: 0;
      }
    }

    &__label {
      top: -2.5rem;
      font-size: 2rem;
      line-height: 2.5rem;
      max-width: 18rem;
      @media (max-width: @screen-xs-max) {
        font-size: 1.6rem;
      }
      @media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
        margin-left: -2.5rem;
        max-width: 35%;
      }
      @media (max-width: (@screen-xs-min - 1)) {
        top: 0.5rem;
      }
    }
  }

  &__question {
    position: relative;
//    font-weight: bold;

    // i {
    //   position: absolute;
    //   left: -1.7rem;
    //   top: -0.1rem;
    //   font-size: 1.5rem;
    // }
  }

  &__recommendation {
    .with-shadow();
    .box-content();
    width: 100%;
    margin-bottom: 2rem;
    display: table;

    &__button {
      margin-bottom: 4rem;
    }

    &__text {
      display: table-cell;
      width: 50%;
      vertical-align: top;
    }

    &__visualization {
      width: 50%;
      height: 100%;
      display: table-cell;
      position: relative;
      vertical-align: middle;

      &__fav {
        position: absolute;
        top: 0px;
        right: -20px;
      }
    }

    &__students {
      margin-top: 2rem;

      p {
        font-size: 1rem;
      }

      &__groups {
        margin-top: 2rem;
      }
    }

    @media (min-width: @screen-sm-min) {
      &__text {
        padding-right: 2rem;
      }
      &__visualization {
        padding-left: 2rem;
        border-left: 1px solid @gray-light;
      }
    }

    @media (max-width: @screen-xs-max) {
      display: block;

      &__text {
        display: block;
        width: 100%;
      }

      &__visualization {
        display: block;
        width: 100%;
        height: auto;
        margin-top: 3rem;

        .stats__panel {
          max-height: none;
        }
      }
    }
  }

  &__negotiation-repeat-message {
    margin: 1rem 0 2rem 0;
  }



  &__highlights {
    .with-shadow();
    .box-content();
    margin-bottom: 4rem;

    &.stats__students__table {
      padding: 0.1rem 0.1rem;
    }

    h6 {
      margin-bottom: 32px;

      .link {
        cursor: pointer;
        float: right;

        i {
          color: red;
          margin-right: 6px;
        }
      }
    }

    .highlight {
      position: relative;

      &__value {
        font-weight: 500;
        font-size: 2rem;
        line-height: 2rem;
        height: 2rem;
        color: #666666;

        &-pointer {
          cursor: pointer;

          &:after {
            content: '\25bc';
            font-size: 0.5em;
            display: inline-block;
            vertical-align: top;
            margin-left: 0.7em;
            margin-top: 0.1em;
            transform: scaleX(1.5);
          }

          &:hover {
            color: #000000;
          }
        }
      }

      &__label {
        font-size: 0.9rem;
      }
    }

    .classes__table-wrapper {
      &__labels__header, &__header {
        tr {
          th, td {
            height: 6em;

            .xcell, .xcell-label {
              height: 100%;

              .stats__comment {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }

      .xcell {
        text-align: center;
        vertical-align: middle;
      }
    }
  }

  &__students-wrapper {
    position: relative;

    .highlight-dataset {
      left: 0;
      margin-top: 0.5rem;

      &--open {
        width: 20.5rem;
      }

      &__item {
        width: 20rem;
        min-height: 2.4rem;
        line-height: 1.9rem;

        button {
          line-height: 1rem;
        }

        button.remove {
          color: @brand-danger;
        }
      }
    }
  }

  &__students-button {
    cursor: pointer;
  }

  &__students-list {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;

    .btn.bordered {
      margin-top: 0px;
      margin-bottom: 4px;
      padding-top: 1px !important;
      padding-bottom: 1px !important;
    }

    &-item {
      position: relative;
      font-size: 16px;
      line-height: 22px;
      color: #333;
      background-color: rgba(0,0,0,0.06);
      padding: 4px 6px 2px 6px;
      border-radius: 3px;
      margin-right: 12px;
      margin-bottom: 4px;
      position: relative;

      i {
        color: @blue;
        cursor: pointer;
      }

      &.disabled {
        cursor: not-allowed !important;
      }

      &--selectable{
        cursor: pointer;
      }

      &--selected{
        color: white !important;
        background-color: @green !important;
      }

      &--success {
        color: @brand-success;
      }
      &--warning {
        color: @brand-warning;
      }

      &--notification {
        &::after {
          position: absolute;
          width: 1rem;
          height: 1rem;
          border-radius: 0.5rem;
          background: @brand-bad;
          content: "";
          top: -0.4rem;
          right: -0.5rem;
        }
      }

      &:hover {
        background-color: rgba(0,0,0,0.14);
      }

      &--inactive {
        color: #999!important;
        background-color: transparent!important;
      }
    }
  }

  &__comment {
    line-height: 1.2em;
    color: black;
    font-weight: normal;
    padding: 8px 2px 2px 2px;
    border-radius: 3px;
    text-align: center;

    &__small {
      font-size: 0.9em;
      line-height: 1.2em;
      white-space: nowrap;

      &--cut {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100px;
        margin: auto;
      }
    }
    & > i {
      display: block;
      margin-bottom: 14px;
      font-size: 1.5em;
    }
    &__text {
      width: 100%;
      height: 1.2em;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.75em;
      @media (min-width: @screen-xl-min) {
        font-size: 0.85em;
      }

      i {
        opacity: 0.8;
        margin-left: 3px;
        font-size: 0.9em;
      }
    }
  }

  &__groups {
    th, td {
      text-align: center;
      vertical-align: middle !important;
    }
    td {
      border-left: 1px solid #ddd;

      p {
        margin: 0.3rem;
      }
    }

    span.danger {
      color: @brand-danger;
      font-weight: bold;
    }

    &__id {
      .xcell-label span {
        font-size: 1.5em;
      }
    }

    &__clickable {
      cursor: pointer;
      color: @gray;
      border-left: 1px solid #ddd;
      &--selected, &:hover {
        color: lighten(@gray, 15%);
      }

      .stats__comment {
        color: white;
        background-color: @blue-base;
        &:hover {
          background-color: darken(@blue-base, 10%);
        }
      }
    }
  }
  &__step > &__comment__text > span::before {
    counter-increment: steps;                 /* Increment the value of section counter by 1 */
    content: counter(steps) ". ";                  /* Display the value of section counter */
  }

  &__individual {
    &__id {
      font-size: 1em;
      text-align: left !important;
    }

    &__id-head {
      text-align: left !important;
    }

    &__sortable {
      position: relative;
      cursor: pointer;

      &--selected {
        .stats__comment {
          background-color: #5CBC8C !important;
          color: white !important;
          &:hover {
            background-color: darken(#5CBC8C, 10%) !important;
          }
        }
      }
      .stats__comment {
        background-color: rgba(0, 0, 0, 0.05);
        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  &__export-btn {
    margin-bottom: 1rem;
  }

  .tasks_list {
    margin-bottom: 10px;

    .dashboard {
      &__tag {
        min-width: 500px;

        &.deleted {
          text-decoration: line-through;
        }

        .tasks_list_row__name {
          min-width: 70px;

          font-size: 1.2em;
          font-weight: bold;
          margin-right: 1rem;
          margin-left: 1rem;
          text-align: center;
          display: inline-block;
        }
      }
    }

    .stats__deletion {
      font-size: 2.2em;
    }
  }
}

.stats__students__table{
  .stats{
    &__highlights{
      &__groups-table {
         overflow: visible;
       }
    }
  }
}

.doughnut {
  display: inline-block;
  position: relative;
  width: 300px;
  height: 158px;
  overflow: hidden;

  &--small {
    width: 210px;
    height: 138px;
  }

  &__text {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;

    //display: flex;
    //justify-content: center;
    //align-items: center;

    text-align: center;

    div {
      margin-top: -1.7rem;
      height: 3rem;
      line-height: 3rem;
      font-size: 3rem;
      font-weight: bold;
    }

    small {
      font-size: 1.5rem;
    }
  }

  &--small &__text {
    div {
      font-size: 2rem;
    }
    small {
      font-size: 1rem;
    }
  }
}


.stats-durations {
  .with-shadow();
  .box-content();
  margin-bottom: 4rem;

  //display: flex;
  //align-items: flex-end;
  padding-top: 40px;

  &__table {
    display: table;
    border-spacing: 1px;
  }
  &__row {
    display: table-row;
  }
  &__table, &__row {
    width: 100%;
  }
  &__cell {
    display: table-cell;
    vertical-align: bottom;
  }

  &__item {
    //flex-grow: 1;
    position: relative;
    text-align: center;
    //margin-right: 1px;

    border-top-left-radius: 0.8em;
    border-top-right-radius: 0.8em;

    border-bottom: 2px solid;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      filter: brightness(90%);
    }
  }

  &__link {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__label {
    position: absolute;
    right: 0;
    left: 0;
    top: -30px;
    font-size: 1em;
  }

  &__icon {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 10px;

    color: white;
    font-size: 1.5em;
  }

  &__select {
    max-width: 400px;
  }
}

.stats-detail {
  .with-shadow();
  .box-content();
  margin-bottom: 2rem;
  @media (max-width: @screen-xs-max) {
    flex-wrap: wrap;
  }
  .whole-width-div {
    width: 100%;
    flex-grow: 1;
    padding-top: 18px;
    button {
      width: 100%;
      display: block;
      margin: 0 auto;
    }
  }

  .doughnut {
    // margin-top: 100%;
    left: -1.5rem;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;

    &--small {
      left: -1.5rem;
    }

    // & > * {
    //   margin-top: -50%;
    // }
  }

  display: flex;
  flex-direction: row;

  &__doughnut {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__students {
    z-index: 5;
    position: absolute;
    top: 100%;
    width: 0;
    max-height: 128px;
    overflow: hidden;
    transition: all .5s;
    background-color: #ddd;
    overflow-x: hidden;

    div {
      padding: 2px 8px;
      width: 240px;
    }
  }

  &__wrapper {
    position: relative;

    &:hover {
      .stats-detail__students {
        width: 240px;
        overflow-y: auto;
      }
    }
  }

  &__attempt {
    margin-bottom: 1rem;
    padding-left: 1rem;

    &--correct-first, &--correct {
      strong, .stats-detail__duration {
        color: @brand-success;
      }
    }

    &--correct-second, &--partial {
      strong, .stats-detail__duration {
        color: @brand-warning;
      }
    }

    &--other, &--wrong {
      strong, .stats-detail__duration {
        color: @brand-danger;
      }
    }
  }

  &__duration {
    padding-left: 1rem;
    color: #999999;

    .fa {
      padding-right: 0.3rem;
    }
  }
}

.negotiation {
  &__nav {
    border-top: 1px solid @gray-light;
    border-bottom: 1px solid @gray-light;
    padding: 0.7rem 0;
    margin-bottom: 2rem;

    &__text {
      font-size: 1.2em;

      &__label {
        display: block;
        height: 1.5rem;
        opacity: 0.8;
      }

      strong {
        font-weight: normal;
        font-size: 1.7em;
        display: block;
        margin-top: 1.3rem;
      }
    }
  }

  &__students-block {
    margin-bottom: 2rem;

    .text-danger {
      margin-top: 0.5rem;
    }

    &__wrapper {
      transition-property: all;
      transition-duration: 600ms;
      transition-timing-function: linear;
      overflow: hidden;

      &--hidden {
        height: 0;
      }
    }
  }

  &__students {
    padding: 0.2rem 1rem 1rem 1rem;

    ul {
      font-size: 1.2em;
      margin-left: -1rem;
    }

    li, .form-group {
      @media (min-width: @screen-sm-min) {
        float: left;
        margin-bottom: 0;
        width: 50%;
      }

      @media (min-width: @screen-md-min) {
        width: 33.333%;
      }
    }

    input[type="checkbox"]:checked::after {
      background-color: #999;
      border-color: #999;
    }
  }

  &__checkbox-label {
    display: inline-block;
    margin-left: 1rem;
    position: relative;

    &--del {
      text-decoration: line-through;
      opacity: 0.5;
    }

    &__unfinished {
      display: block;
      white-space: pre;
      position: absolute;
      left: 0;
      bottom: -1.2rem;
      font-size: 0.8em;
    }

    &__exam-info {
      display: block;
      white-space: pre;
      position: absolute;
      left: 0;
      bottom: -1.2rem;
      font-size: 0.8em;
      color: #777;
      font-weight: normal;
    }
  }

  &__toggle-results {
    margin-bottom: 2rem;
  }

  &__group.exam {
    border-style: solid;
    border-width: @border-radius-base;
    border-color: @block-border-color;

    .rewards {
      position: absolute;
      right: 0.5rem;
      top: -2rem;
      font-size: 4rem;
    }

    .panel-body {
      padding: 0;
    }

    .negotiation__group__header {
      padding-top: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .even {
      background-color: #f0f0f0;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;
    }

    .odd {
      background-color: @white;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;
    }

    .result-fail{
      font-weight: bold;
    }

    .result-success{
      font-weight: bold;
    }

    .result-success-subtitle{
      font-size: 1rem;
      color: @brand-success;
      font-weight: bold;
    }

    .result-fail-subtitle{
      font-size: 1rem;
    }

    .exam-part {
      min-height: 2.3rem;
    }

    .btn.bordered {
      margin-top: 0.5em;
      margin-bottom: 0px;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }

    .btn.full {
      margin-top: 0.5em;
      margin-bottom: 0px;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }

    .hidden_button {
      // display: none;
      opacity: 0;
      visibility: hidden;
      -webkit-transition: opacity 600ms, visibility 600ms;
      transition: opacity 600ms, visibility 600ms;
    }

    .exam-part:hover > .hidden_button {
      //display: inline-block;4
      visibility: visible;
      opacity: 1;
    }
  }

  &__group {
    font-size: 1.5em;

    .panel-body {
      padding: 1rem;
    }

    &__header {
      font-size: 0.8em;
      margin-bottom: 1rem;

      i {
        margin-right: 0.5rem;
      }
    }

    &__value {
      height: 1.6em;
      font-size: 0.8em;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.control {
  .text-center;
  width: 5rem;
  font-size: 450%;

  a {
    display: block;
    text-decoration: none;
  }

  &--margin a {
    margin-bottom: 20px;
  }

  &__label {
    display: block;
    font-size: 20%;
  }

  &--next a {
    color: @brand-success;

    &:hover {
      color: darken(@brand-success, 10%);
    }

    &.disabled {
      color: @gray-light;
    }
  }

  &--prev a {
    color: lighten(@gray, 10%);

    &:hover {
      color: @gray;
    }
  }
}

.color-success {
  color: lighten(@brand-success, 10%); // rgb(141, 188, 67)
}

.color-failure {
  color: @brand-warning;
}


.rewards {
  height: 10rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  .reward {
    height: 100%;
    line-height: 10rem;
    font-size: 8rem;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 2rem;

    &--selected {
      border: 2px;
      border-radius: 5%;
      border-color: @gray;
      border-style: solid;
    }
  }
}


.exam-panel {
  border-top:2px @gray-light solid;
  padding-top: 1rem;
  padding-bottom: 1rem;

  span{
    vertical-align: middle;
    display: inline-block;
  }

  .exam-show-correct-answer{
    display: inline;
    &__button{
      float: right;
    }
  }

  .exam-answer {

    display: inline;
    margin-left: 2rem;
    margin-top: 2rem;
    vertical-align: middle;


    .exam-answer-button{
      vertical-align: middle;
      display: inline;
      margin-right: 2rem;

      &__success{
        color: white!important;
        background-color: @brand-success;
        &:hover,
        &:focus,
        &.active {
          background-color: darken(@brand-success, 10%)!important;
        }
      }

      &__fail{
        border: 2px solid @brand-danger;
        color: @brand-danger!important;
        background-color: transparent;

        &:hover,
        &:focus,
        &.active {
          color: #fff !important;
          background-color: @brand-danger!important;
        }
      }

    }
  }
}

.invisible{
  visibility: hidden;
}
