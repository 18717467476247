.dashboard {
  margin-bottom: 2.5em;

  &__tag {
    position: relative;
    float: none !important;
    display: inline-block !important;

    &:last-of-type {
      margin-right: 0 !important;
    }

    &--tight, &--tight-and-short {
      margin-right: 0.2rem !important;
    }

    &&--tight a {
      padding-top: 10px !important;
      padding-bottom: 0px !important;
      min-height: 70px;
    }

    &&--tight i {
      margin-bottom: 3px;
    }

    &--tight-and-short a {
      margin-bottom: 0 !important;
    }

    margin: 0.5rem 0.8rem 0.5rem 0 !important;

    &__description {
      display: inline-block;
      vertical-align: middle;
    }

    &--small &__description {
      font-size: 0.8em;
      line-height: 0.9em;
    }

    &&--no-icon {
      a {
        padding-bottom: 6px !important;
      }
    }

    a {
      display: block;
      text-decoration: none;
      margin-left: 0px;
      border-radius: 4px!important;
      background: rgba(0,0,0,0.06);
      color: @text-color;
      padding: 6px 8px 2px 8px!important;
      min-width: 44px;
      &:hover {
        background: rgba(0,0,0,0.14) !important;
        color: @text-color;
      }
    }

    &--fixed-size {
      width: 64px;
      overflow: hidden;
      .text-center;

      a {
        padding: 12px 4px!important;
      }

      &-small {
        width: 4em;
        .text-center;
      }
    }

    &.disabled, &.disabled:hover {
      a {
        border-color: transparent !important;
        cursor: not-allowed;
        color: @gray;
      }
    }

    &.active, &.active:hover {
      a {
        background-color: @brand-success!important;
        color: white!important;
      }
    }

    .hide-text{
      //visibility: hidden;
      opacity: 0;
      //color: @brand-success
    }

    .loader::after {
      content: "";
      position: absolute;
      width: 18px;
      height: 18px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border: 5px solid transparent;
      border-top-color: #ffffff;
      border-radius: 50%;
      animation: button-loading-spinner 1s ease infinite;
    }

    @keyframes button-loading-spinner {
      from {
        transform: rotate(0turn);
      }

      to {
        transform: rotate(1turn);
      }
    }




    &__add {
      background: transparent !important;
      color: @brand-primary !important;

      &:hover {
        color: @white !important;
      }
    }

    &__notifications {
      @badge-size: 1.2; //rem

      display: block;
      position: absolute;
      right: -@badge-size / 2rem;
      top: -@badge-size / 2rem;
      width: @badge-size * 1rem;
      height: @badge-size * 1rem;
      border-radius: @badge-size * 1rem;
      line-height: @badge-size * 1rem;
      text-align: center;
      font-size: @badge-size * 0.8em;
      font-weight: bold;
      color: #fff;
      background: @brand-bad;
    }
  }

  input[type="checkbox"] {
    margin: 0;
  }

  &__modal {
    //padding-left: 1.3rem;
    min-height: 20rem;

    &-label {
      display: inline-block;
      margin: 0.5rem;
      font-size: 1rem;
      line-height: 1.2rem;
      height: 1.8rem;
      &-add {
        cursor: pointer;
      }
    }

    h5 {
      margin-top: 3rem;
    }
  }

  &-addclass {
    display: inline-block;

    .btn {
      margin: 0.25rem 1rem 0.5rem 0;
      font-size: 1rem;
      line-height: 1.1rem;
      height: 1.9rem;
      border: 0px;
      vertical-align: middle;
    }
  }
}
