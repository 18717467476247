@color-facebook: #3b5998;
@color-google: #dd4b39;
@color-microsoft: #2672ec;

.with-shadow {
  .box-shadow(0 1px 2px rgba(0,0,0,.3));
}

.box-content() {
  background-color: #fff;
  padding: 1.5rem 3rem;
  border-radius: 3px;
}

.status-good {
  color: @brand-success;
}

.status-normal {
  color: @brand-warning;
}

.status-bad {
  color: @brand-bad;
}

.status-danger {
  color: @brand-danger;
}
