@GREEN: @brand-success;
@LIGHT_GREEN: lighten(@GREEN, 5);

.homepage {
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 300;
  }

  h1 {
    margin: 5rem 0 3rem 0;
    font-size: 40px;
  }

  h2 {
    font-size: 40px;
    margin-bottom: 4rem;
  }

  h3 {
    font-size: 25px;
    margin-bottom: 2rem;
  }

  h5 {
    font-size: 2.4rem;
    margin:  0 0 35px 0;
    font-weight: 100;
  }


  section {
    padding: 5rem 0;
  }

  img {
    max-width: 100%;
  }

  @icon-opacity: 0.75;
  .fa-bullhorn {
    color: #e01c90 ;
    opacity: @icon-opacity;
  }
  .fa-lightbulb-o {
    color: #fbc700;
    opacity: @icon-opacity;
  }
  .fa-coffee {
    color: @GREEN;
    opacity: @icon-opacity;
  }

  .btn-primary {
    background-color: @GREEN;
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding: 10px 46px;
    font-weight: 400;
    font-size: 18px;
    &:hover, &:active {
      background-color: @LIGHT_GREEN;
    }
  }

  &__top {
    background-image: url("https://s3.eu-central-1.amazonaws.com/techambitioncom/homepage/images/bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0 0 5rem 0 !important;

    @media(min-width: @screen-sm-min) {
      padding: 5rem 0;
    }

    .btn-primary {
      margin-bottom: 3rem;
    }

    .fa-chevron-down {
      color: @GREEN;
      margin-top: 2rem;
      font-size: 3rem;
      cursor: pointer;
      transition: 0.2s;
      &:hover{
        color: @LIGHT_GREEN;
      }
    }

    p {
      max-width: 40rem;
      font-weight: 500;
      margin: 2rem auto 1rem auto;
    }
  }

  &__gifs {
    .block-col {
      padding-bottom: 2rem;
      position: relative;
      margin-bottom: 2rem;
      margin-top: 2rem;

      @media(min-width: @screen-sm-min) {
        margin-bottom: 6rem;
      }
      @media(min-width: @screen-lg-min) {
        margin-bottom: 7rem;
      }
    }
    .text-block-col {
      margin: auto;
      margin-top: 2rem;
      max-width: 30rem;
      padding: 0 2rem;
      @media(min-width: @screen-sm-min) {
        padding: 0 0 2rem 5rem;
      }
    }
    .text-block-col-left {
      .text-block-col();
      @media(min-width: @screen-sm-min) {
        padding: 0 5rem 2rem 0;
      }
    }
    .tablet-1 {
      position: relative;
      width: 100%;
      height: auto;
      top: 0;
      left: 0;
      margin-top: 1rem;

      @media(min-width: @screen-sm-min) {
        position: absolute;
        width: 60%;
        top: 13rem;
        left: 0;
      }

      @media(min-width: @screen-md-min) {
        position: absolute;
        width: 57%;
        height: auto;
        top: auto;
        left: auto;
        bottom: -6rem;
        right: -4rem;
      }

      @media(min-width: @screen-lg-min) {
        right: -13rem;
        bottom: -7rem;
      }
    }
    .phone-2 {
      position: relative;
      max-width: 20rem;
      margin: 2rem auto;
      display: block;

      @media(min-width: @screen-sm-min) {
        position: absolute;
        width: 30%;
        top: 8rem;
        left: -4rem;
      }

      @media(min-width: @screen-md-min) {
        position: absolute;
        width: 25%;
        top: 9rem;
        left: -5rem;
      }
    }
    .phone-3 {
      position: relative;
      max-width: 20rem;
      margin: 2rem auto;
      display: block;

      @media(min-width: @screen-sm-min) {
        position: absolute;
        width: 25%;
        right: -3rem;
        top: 10rem;
      }
    }
  }

  &__demo-col {
    height: auto;
    margin: 1rem auto 3rem;
    padding: 0 2.5rem;
    h5 {
      height: 2em;
    }

    &__image {
      margin: 1rem auto;
      max-width: 200px;
    }

    button {
      width: 100%;
      max-width: 25rem;
      font-size: 0.85em;

      @media (max-width: @screen-md-max) and (min-width: @screen-md-min) {
        font-size: 0.7em;
      }
    }

    p {
      margin: 1rem auto 0 auto;
      max-width: 25rem;
    }
  }

  &__info-col {
    &__icon {
      font-size: 8em;
      margin-bottom: 2rem;
    }

    p {
      margin: 1rem auto 0 auto;
      max-width: 25rem;
    }
  }

  &__school {
    height: 15rem;

    &--reference {
      height: 30rem;
    }

    &__teacher {
      font-weight: bold;
      margin-top: -0.5em;
    }

    &__reference {
      font-style: italic;
    }

    &__image {
      max-width: 200px;
      height: 100px;
      line-height: 100px;
      margin: 0 auto 1rem auto;

      img {
        max-height: 100px;
        vertical-align: middle;
      }
    }
  }

  &__past {
    height: auto;
    margin-bottom: 2rem;
    @media(min-width: @screen-sm-min) {
      margin: 0 auto 0;
    }

    p {
      max-width: 25rem;
      margin: auto;
    }

    &__image {
      max-width: 250px;
      height: 200px;
      line-height: 200px;
      margin: 0 auto 1rem auto;

      img {
        max-height: 200px;
        vertical-align: middle;
      }
    }

    h3 {
      margin-top: 0;
    }
  }

  &__light {
    position: relative;
    width: 100%;
    background: #fff;
  }

  &__footer {
    padding: 20px 0 10px;
    color: black;
  }

  &__lesson-btn {
    font-size: 1.7em;
    margin: 0.3rem;
  }

  &__video {
    width: 100%;
    max-width: 560px;
    height: 315px;
    display: block;
    margin: 2rem auto;
  }
  h3{
    margin-bottom: 3rem;
  }
  h4 {
    margin-bottom: 4rem;
  }

  .call-to-action {
    .btn {
      margin-top: 5rem;
    }
  }

  .scroll {
    .arrow {
      font-size: 6em;
      line-height: 1em;
    }
  }

  .orange {
    color: #fa6f00;
  }

  &__lessons {
    margin-bottom: 3rem;
  }

  &__team {
    margin-top: 0;
    text-align: center;

    @media(min-width: @screen-sm-min) {
      text-align: left;
    }
    .try-it {
      position: relative;
      text-align: center;
      padding-top: 1.1rem;
      max-width: 29rem;
      margin: auto;
      .btn-primary {
        margin-top: 1rem;
      }

      .arrow {
        position: absolute;
        top: 40px;
        right: -15px;

        @media(min-width: @screen-sm-min) {
          right: -35px;
        }
        @media(min-width: @screen-md-min) {
          right: -6px;
        }
      }

      h5 {
        font-size: 30px;
        margin: 0 0 15px 0;
        font-weight: 300;
      }
    }

    &__image {
      max-width: 100%;
    }

    &__info {
      margin-top: 1rem;
    }

    &__item {
      margin-bottom: 1.5rem;
      font-size: 1.2em;

      @media(min-width: @screen-sm-min) {
        font-size: 1.8em;
      }

      &:first-child {
        margin-top: 2.2rem;
      }

      &__icon {
        font-size: 1.5em;
        display: inline-block;
        width: 1.5em;
        vertical-align: middle;
        .fa-envelope-o {
          position: relative;
          top: -3px;
        }
      }
    }

    &--small {
      margin-top: 2em;
    }

    &--small &__item {
      font-size: 1em;
      margin-bottom: 0.5em;
    }

    &--small &__image {
      @media (min-width: @screen-sm-min) {
        float: right;
      }
      max-width: 80%;
    }
  }
}

@RED: #e80300;
@GREEN: #8dbc43;
.pricing-page {
  font-size: 1.1em;
  overflow-x: hidden;

  h1 {
    font-weight: 100;
    font-size: 50px;
  }

  h2 {
    font-weight: 400;
    font-size: 1.2rem;
    margin-top: 40px;
  }

  .btn-primary {
    background-color: @GREEN;
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding: 10px 46px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    font-weight: 400;
    font-size: 18px;
    &:hover, &:active {
      background-color: @LIGHT_GREEN;
    }
  }

  .text-line-through {
    text-decoration: line-through;
    margin-bottom: 0.3rem;
  }

  table.pricing-table {
    display: block;
    overflow-x: auto;
    max-width: 100%;
    border-collapse: collapse;
    margin: 5rem 1rem 2rem;
    line-height: 130%;
    th, td {
      padding: 0.7rem;
      width: 24%;
    }
    thead {
      th {
        font-weight: 300;
        text-align: center;
        font-size: 25px;
        line-height: 30px;
        &:first-child {
          width: 28%;
        }
      }
    }
    tbody {
      tr:nth-child(even) {
        background: white;
      }
      th {
        font-weight: 400;
      }
      td {
        text-align: center;
      }
    }
    tfoot {
      td {
        text-align: center;
        strong {
          font-size: 1.2rem;
          font-weight: 700;
        }
      }
    }

    .fa-check {
      color: @GREEN;
      font-size: 1.2rem;
    }
    .fa-times {
      color: @RED;
      font-size: 1.2rem;
    }
  }
}

.contacts {
  font-size: 4rem;
  text-align: center;
  padding-top: 7rem;

  @media (max-width: @screen-xs-max) {
    & {
      padding-top: 4rem;
      font-size: 2.2rem;
    }
  }

  &__link-container {
    margin-top: 8rem;

    a {
      i {
        font-size: 1.5em;
        padding: 0 3rem;
      }
    }
  }
}

.walkthroughs {
  overflow: hidden;


  &__section-title {
    margin: 2rem auto;
    cursor: pointer;
  }

  &__subsection-title {
    margin: 0 auto 1rem auto;
    cursor: pointer;
  }

  &__section {
    overflow: hidden;
  }

  &__image {
    width: 100%;
    margin: 0.5em 0 3em 0;
  }

  &__tip {
    padding-top: 1em;

    &__left {
      background: lighten(@brand-primary, 20%);
      padding: 1rem;
      margin-top: 0.5em;
      color: #333;

      strong {
        color: #fff;
      }
    }

    i {
      font-size: 5em;
      margin-bottom: 0.3em;
    }
  }
}

.index-nav {
  height: 0;
  @media (max-width: @screen-sm-max) {
    height: 64px;
  }

  .navbar-default {
    background-color: transparent!important;
  }
  .menu-button--special{
    background-color: transparent;
    color: @blue-base;
    border-color: @blue-base;
  }
  .dropdown-menu a {
    color: #666!important;
  }
  .menu-button-wrapper{
    background-color: transparent!important;
    a {
      background-color: transparent!important;
    }
  }
  .navbar-collapse {
    &.in {
      background-color: #fff;
    }
    &.collapsing {
      background-color: #fff;
    }
  }
  .nav-background {
    background-color: white;
    position: fixed;
    z-index: 59;
    width: 100%;
    top: 0;
    height: 64px;
    transition: height 0.5s;
  }
  .navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
    color:  #000!important;
    background-color: transparent!important;
  }
  &.active {
    //  .dropdown ul li a {
    //   color: #666!important;
    //  }
    // .navbar-collapse {
    //   &.in {
    //     background-color: #5cbb8b;
    //   }
    //   &.collapsing {
    //     background-color: #5cbb8b;
    //   }
    //   .dropdown-menu a{
    //     @media (max-width: @grid-float-breakpoint-max) {
    //       color: white!important;
    //     }
    //   }
    // }

    @media (min-width: @screen-md-min) {
      .nav-background {
        box-shadow: none;
        height: 0px;
      }

      &.light-logo {
        .logo {
          display: none;
          &.light {
            display: block;
          }
        }
      }

      &.light-text {
        .nav {
          li a {
            color: white;
          }
          li a:hover {
            color: @gray-light;
          }
          li.active a {
            color: #fff !important;
            text-shadow: @gray-light 0 0 5px;
          }

          .menu-button--special {
            color: white;
            border-color: #11D1A7;

            &:hover {
              background: #11D1A7;
            }
          }
        }
      }
    }

    // .navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
    //   color:  #fff!important;
    //   background-color: transparent!important;
    // }
    // .menu-button-wrapper.active{
    //    .menu-button--special{
    //      background-color: transparent!important;
    //   }
    // }
    // .menu-button--special {
    //   color: white;
    // }
    // .menu-button--special:hover {
    //   color: @blue-base;
    // }
    // .navbar-default {
    //   background-color: transparent!important;
    //   li {
    //     a {
    //       color:white;
    //     }
    //     a:hover {
    //       color: #666;
    //     }
    //   }

    //   .menu-button-wrapper{
    //     background-color: transparent;
    //     a {
    //       background-color: transparent!important;
    //       .menu-button{
    //         color: #fff!important;
    //         border-color: #fff!important;
    //       }
    //     }
    //     a:hover {
    //       background-color: transparent!important;
    //       .menu-button{
    //         color: #666!important;
    //         border-color: #666!important;
    //         background-color: transparent!important;
    //       }
    //     }
    //   }
    // }
  }
}
