.classes {
  &__table-wrapper {
    background-color: white;
    margin-bottom: 32px;
    position: relative;
    overflow: hidden;

    &__labels {
      position: absolute;
      padding: 0.8em 0 0 0.8em;
      margin-left: -1px;

      &__body {
        //   max-height: 30rem;
        //   overflow: hidden;
        margin-bottom: 0.8em;

        &__inner {
          height: 10000px;
        }
      }
    }

    &__header,
    &__body {
      margin-left: 13.6em;
    }

    &__header {
      padding: 0.8em 0.8em 0 0;
      overflow: hidden;

      &__inner {
        width: 10000px;
      }
    }

    &__header,
    &__labels__header {
      tr {

        th,
        td {
          height: 4em;
        }
      }
    }



    &__body {
      padding: 0 0.8em 0.8em 0;
      overflow: auto;
      overflow-y: hidden;
      cursor: grab;
      user-select: none;
      -webkit-user-select: none;
      // height: 30rem;
    }

    &--small &__labels__body {
      max-height: 31.6em;
    }

    &--small &__body {
      height: 31.6em;
    }

    &__body,
    &__labels__body {
      tr {

        th .xcell,
        td .xcell {

          &,
          &-label {
            height: 3.5em;
            overflow: hidden;
          }
        }
      }
    }

    .table {
      width: auto;
      max-width: none;
      table-layout: fixed;
      margin-bottom: 0;

      tr {

        th,
        td {
          padding: 0.4em;
        }

        .xcell, .xcell-label {
          display: flex;
          align-items: center;
          justify-content: left;
        }

        .xcell {
          justify-content: center;
          text-align: center;
          width: 4.5em;

          &--sm {
            width: 3em;
          }

          &--lg {
            width: 5.5em;

            // Font is increased (in app.less), increase column width to fit everything in it.
            @media (min-width: @screen-xl-min) {
              width: 6em;
            }
          }

          &--auto {
            width: auto;
          }

          &__icon {
            font-size: 1.6em;

            &--large {
              font-size: 2.8em;
            }
          }
        }

        .xcell-label {
          width: 12em;
        }
      }
    }

    .annotation .annotation-wrapper {
      top: 65%;
    }
  }

  &__chart__wrapper {
    background-color: white;
    width: 100%;
    margin-bottom: 32px;
    position: relative;
  }

  &__box {
    min-height: 6em;
    display: inline-block;
    margin-right: 1em;
    margin-bottom: 1em;
    padding-right: 5em;
    position: relative;
    overflow: visible;
    opacity: 1;

    transition-property: opacity;
    transition-duration: 500ms;
    transition-timing-function: cubic-bezier(0.175, 0.665, 0.320, 1);

    &--hidden {
      opacity: 0.3;
    }

    &__stats {
      width: 4em;
      position: absolute;
      right: 0.5em;
      top: 1.3em;
    }

    &__hide {
      position: absolute;
      right: -0.6em;
      top: -0.6em;
      display: none;
    }

    &:hover {
      .classes__box__hide {
        display: inline-block;
      }
    }
  }

  &__add-button {
    height: 6em;
    width: 6em;
    vertical-align: top;

    &>span {
      font-size: 5em;
      line-height: 1em;
    }
  }

  &__add-modal {
    padding: 1em;
  }

  &__add-input {}

  &__detail {
    width: 100%;

    .stats__subtitle {
      margin-left: 0.2em;
    }

    &__name {
      min-height: 2em;
      float: right;

      @media (max-width: @screen-sm-max) {
        float: none;
      }

      .row {
        margin-right: -55px;
        margin-top: -10px;

        @media (max-width: @screen-sm-max) {
          margin-bottom: 2rem;
        }
      }

      h4 {
        font-size: 1rem;
        cursor: pointer;
        padding-left: 10px;
      }

      &__edit,
      &__hide,
      &__show {
        display: inline;
        font-size: 0.7em;
      }

      &__hide {
        color: @btn-danger-bg;
      }

      &__show {
        color: @btn-success-bg;
      }

      button {
        margin-top: 0.65em;
      }

      input {
        margin-top: 0.3em;
        font-size: 2.1em !important;
        padding-left: 0.18em !important;
      }
    }

    canvas {
      margin-top: 2em;
    }

    &__chart-pointer {
      cursor: pointer;
    }

    &__legend {
      font-size: 1.3em;
      margin-top: 2rem;

      &__item {
        height: 2em;
        line-height: 2em;

        &__color {
          display: inline-block;
          width: 2rem;
          height: 0.15rem;
          vertical-align: middle;
          margin-right: 0.5em;
          margin-left: 0.3em;
        }
      }
    }

    .tasks {
      &__heading {
        font-size: @font-size-base;
        line-height: @line-height-base;
      }

      &__show_all {
        margin-top: 0px;
        margin-bottom: @line-height-computed;
        padding-left: 0;
        padding-right: 0;
        text-transform: none;
      }
    }

    &__data {
      margin-top: -1.5rem;
      margin-bottom: 1rem;
    }

    &__data-choose {
      margin-top: 1.5rem;
    }
  }

  .lessons-type-filter {
    margin-bottom: 1rem;
  }
}

.danger-link {
  cursor: pointer;
  float: left;
  margin-bottom: 4px;

  .fa-times.red {
    color: red;
    margin-right: 4px;
  }
}


.stats__students__table, .classes__detail__table {
  .classes {
    &__table-wrapper {
      overflow: visible;

      &__labels {
        &__header {
          position: sticky;
          top: 121px;
          top: calc(110px + 0.8em);
          z-index: 1;
          background-color: white;
        }

        &__body {
          &__inner {
            height: auto;
          }
        }
      }

      &__header {
        position: sticky;
        top: 110px;
        z-index: 1;
        background-color: white;
      }

      &__body {
         height: auto;
       }
    }
  }
}
