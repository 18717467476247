.order {
  margin: 0 auto;

  &, h1, h2 {
    color: #363636;
  }

  h1 {
    max-width: 531px;
    height: 86px;
    margin: 0 auto 4rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 15px;
    box-shadow: 0px 1px 2px 0px #00000040;
    font-size: 32px;

    i {
      color: #176CAD;
      margin-right: 1rem;
    }

    @media (max-width: @screen-xs-max) {
      max-width: 200px;
      height: 53px;
      font-size: 16px;
      margin-bottom: 3rem;
    }
  }

  h2 {
    font-size: 20px;
    text-align: center;
    font-weight: 900;
    margin: 0 auto 1rem auto;

    @media (max-width: @screen-xs-max) {
      font-size: 16px;
      margin-bottom: 0.75rem;
    }
  }

  &__panel {
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    .panel-body {
      padding: 1rem 2rem 2rem 2rem;
    }

    &__block {
      width:100%;
      position: relative;
    }

    div {
      display: block;
    }

    .form-group {
      margin-top: 14px;
      width: 100%;
      &-mid{
        margin-top: 14px;
        width: 90%;
      }

      &-small{
        margin-top: 14px;
        width: 80%;
      }
    }

    textarea {
      height: 8rem;
      resize: none;
    }
  }

  &__section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
    font-size: 14px;
    text-align: center;

    @media (max-width: @screen-xs-max) {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    // &--input {
    //   margin-bottom: 28px;
    // }

    .dashboard__tag a {
      border-radius: 15px !important;
    }

    .form-group, input[type=text], input[type=number] {
      width: 100%;
      max-width: 368px;
    }

    input[type=text], input[type=number] {
      box-shadow: inset 0 0 0 3px #2B2F53;
      border-radius: 10px;
      &:focus {
        box-shadow: inset 0 0 0 3px @brand-primary;
      }
    }
    .has-error input[type=text], .has-error input[type=number] {
      box-shadow: inset 0 0 0 3px @brand-danger;
    }

    .form-group .help-block {
      text-align: center;
    }

    .btn {
      min-width: 180px;
      height: 53px;
      font-size: 20px;
      font-weight: 900;
      border-radius: 15px;
      padding: 5px 40px !important;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      &:not(.btn-default) {
        color: white;
      }

      @media (max-width: @screen-xs-max) {
        font-size: 14px;
        height: 37px;
        border-radius: 5px;
      }
    }

    &__description {
      position: relative;
      font-size: 14px;
      font-weight: 900;
      line-height: 19px;
      margin-bottom: 1rem;

      i {
        font-size: 24px;

        @media (min-width: @screen-sm-min) {
          position: absolute;
          right: -30px;
        }

        @media (max-width: @screen-sm-max) {
          display: block;
          margin-top: 10px;
        }
      }

      @media (max-width: @screen-xs-max) {
        font-size: 12px;
      }
    }

    p {
      text-align: center;
    }
  }

  &__bundles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 2rem;

    @media (max-width: @screen-sm-max) {
      justify-content: space-between;
    }
    @media (max-width: @screen-xs-max) {
      margin-top: 0rem;
    }
  }
  &__bundle {
    background: white;
    border-radius: 25px;
    margin: 15px;
    cursor: pointer;
    box-shadow: 0px 1px 4px -1px #00000040;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 35px 20px;
    position: relative;
    overflow: hidden;

    &__sale {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 20px;
      right: -110px;
      width: 340px;
      height: 70px;
      background: #FA7652;
      text-align: center;
      color: #FFFFFF;
      text-shadow: 0px 1px 4px #00000040;
      font-size: 35px;
      font-weight: 900;
      transform: rotate(45deg);

      @media (max-width: @screen-xs-max) {
        height: 30px;
        font-size: 16px;
        top: 10px;
        right: -150px;
      }
    }

    width: 40%;
    @media (max-width: @screen-md-max) {
      width: 45%;
    }
    @media (max-width: @screen-sm-max) {
      width: 49%;
      border-radius: 10px;
      margin-left: 0;
      margin-right: 0;
    }
    @media (max-width: @screen-xs-max) {
      padding: 15px 10px;
      &--top {
        padding: 25px 10px;
      }
    }

    &:last-child:nth-child(2n+1) {
      margin-left: auto;
      margin-right: auto;
    }

    &--selected, &:hover {
      box-shadow: 0px 1px 4px -1px #00000040, inset 0 0 0 5px @brand-success;
    }

    &__icon {
      height: 70px;
      text-align: center;
      margin-bottom: 30px;

      img {
        height: 100%;
        max-width: 100%;
      }

      i {
        font-size: 3rem;
        color: @brand-success;
      }

      @media (max-width: @screen-xs-max) {
        height: 24px;
        margin-bottom: 10px;
      }
    }

    &__title {
      color: #30345D;
      font-size: 20px;
      margin-bottom: 20px;
      min-height: 60px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      @media (max-width: @screen-xs-max) {
        font-size: 12px;
        font-weight: 900;
        line-height: 16px;
        margin-bottom: 10px;
      }
    }

    &__info {
      color: @brand-success;
      text-align: center;
      font-size: 12px;
      line-height: 16px;
      font-weight: 900;
      max-width: 300px;
      height: 80px;

      @media (max-width: @screen-xs-max) {
        font-size: 9px;
        line-height: 12px;
        text-align: left;
        height: 70px;
        width: 100%;
      }

      ul {
        list-style: outside;
        padding-inline-start: 18px;
        font-weight: 700;

        li {
          margin-bottom: 3px;
        }

        span {
          color: #000000;
          margin-left: -3px;
        }
      }
    }

    &__price {
      color: #2B2F53;
      font-size: 24px;
      font-weight: 900;
      margin-bottom: 30px;

      small {
        font-weight: 500;
      }

      @media (max-width: @screen-xs-max) {
        font-size: 16px;
        margin-bottom: 10px;
      }
    }

    &__price-info {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      color: black;
      margin-bottom: 20px;

      @media (max-width: @screen-xs-max) {
        font-size: 9px;
        line-height: 12px;
        margin-bottom: 10px;
        min-height: 30px;
      }
    }

    .btn.btn-success {
      outline: none;
      background: @brand-success !important;

      @media (max-width: @screen-xs-max) {
        font-size: 12px;
        height: 27px;
        min-width: 88px;
        padding: 5px !important;
      }
    }
  }

  &__bundle-alert {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    box-shadow: 0px 1px 4px -1px #00000040;
    border-radius: 25px;
    border: 5px solid @brand-primary;
    min-height: 100px;
    margin-top: 2rem;

    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;

    i {
      position: absolute;
      left: 20px;
      font-size: 40px;
      color: @brand-primary;
    }

    width: calc(80% + 30px);
    @media (max-width: @screen-md-max) {
      width: calc(90% + 30px);
    }
    @media (max-width: @screen-sm-max) {
      width: 70%;
      min-height: 85px;
      padding: 0 30px;
      font-size: 18px;
      line-height: 20px;
      border-radius: 10px;
      i {
        left: 10px;
        font-size: 20px;
      }
    }
    @media (max-width: @screen-xs-max) {
      width: 100%;
      min-height: 65px;
      padding: 0 25px;
      font-size: 11px;
      line-height: 16px;
      margin-top: 1rem;
      i {
        left: 10px;
        font-size: 15px;
      }
    }
  }

  &__bundle-top {
    position: relative;
    overflow: hidden;
    margin-top: 2rem;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    width: calc(80% + 30px);
    @media (max-width: @screen-md-max) {
      width: calc(90% + 30px);
    }
    @media (max-width: @screen-sm-max) {
      width: 100%;
    }

    &__item {
      width: 45%;
    }
  }

  &__bundle-top &__bundle__info {
    font-size: 16px;
    line-height: 24px;
    max-width: 100% !important;
    height: auto !important;
    margin-bottom: 1.5rem;

    @media (max-width: @screen-xs-max) {
      font-size: 11px;
      line-height: 14px;
    }

    ul {
      margin: 0;
    }
  }

  &__teachers, &__teachers-amounts {
    width: 100%;
    max-width: 40rem;
    padding: 2rem;
    box-shadow: 0px 1px 4px -1px #00000040;
    background: white;
    border-radius: 25px;
  }

  &__teachers {
    text-align: left;

    .negotiation__students {
      padding: 2px;
    }

    .form-group {
      margin-top: 0;

      @media (min-width: @screen-md-max) {
        float: left;
        width: 50%;
      }
    }

    input[type="checkbox"]:checked::after {
      background-color: @brand-success;
      border-color: @brand-success;
    }
    .checkbox--checked .control-label {
      color: @brand-success;
      font-weight: bold;
    }

    .control-label {
      padding-right: 10px;
      font-size: 1em;
    }
  }

  &__teachers-amounts {
    .form-group {
      margin-left: auto;
      margin-right: auto;
    }
    @media (min-width: @screen-md-min) {
      text-align: left;

      &__row {
        .clearfix;
        margin-top: 14px;
      }

      &__name {
        float: left;
        width: 80%;
        line-height: 3em;
        padding-right: 10px;
      }

      &__input {
        float: left;
        width: 20%;

        .form-group {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }

    input[disabled] {
      color: transparent;
    }
  }

  &__price {
    margin-top: 0.5rem;
    &__sum {
      font-size: 32px;
      font-weight: 900;
      color: #2B2F53;

      small {
        font-weight: 500;
      }

      @media (max-width: @screen-xs-max) {
        font-size: 20px;
      }
    }
  }

  &__confirm {
    margin-bottom: 1rem;
  }

  &-button {
    position: absolute;
    right: 0px;
    top: -10px;

    &--lesson {
      right: 15px;
    }

    &--filter {
      top: -18px;
      right: 50%;
      transform: translateX(50%);
    }
  }
}
